import React from 'react'
import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import './Privacy.css'

export default function Home() {
  
  return (
   <>
       <Header currentPage="privacy-policy" title="Privacy Policy" />
       <section className="privacy">
           <main>
               <section className="container">
                   <h2>Privacy Policy</h2>
                   <p className="last-updated">Last Updated: February 6, 2020</p>

                   <p>OurCountryOurVote Inc. ("OCOV"), owner of the <a href="https://ocov.us">https://ocov.us
                       web</a> site and the OurCountryOurVote mobile app (collectively, the "Site"),
                       is committed to safeguarding your privacy online. Please read the following statement to
                       understand how your personal information will be treated as you make full use of the Site.</p>

                   <p>OCOV maintains the anonymity of every person who accesses the Site, and who participates in a
                       polls or other features on the Site,
                       except as described in this statement or unless the user has consented in advance to the release
                       of his or her Personally Identifiable
                       Information (defined below). For questions about this Privacy Statement or our collection and use
                       of your Personally Identifiable Information,
                       please contact us at <a href="mailto:support@ocov.us">support@ocov.us.</a></p>

                   <ol>
                       <li>
                           <span>Personally Identifiable Information</span>
                           <p>When we use the term Personally Identifiable Information(“PII”) in our Privacy Policy, we
                               are referring to information that you individually and
                               either directly or indirectly provide to OCOV that can be used to identify you on its own
                               or in combination with other information.
                               PII includes your name, your mailing address, your telephone number, and your
                               e-mail-address, and may include other information that identifies
                               you. Information that by itself cannot be used to identify you, including your age,
                               gender and political affiliation, does not qualify as PII.
                           </p>
                       </li>
                       <li>
                           <span>Information We Collect</span>
                           <p>We collect information in two ways: directly, when you voluntarily provide information to
                               us and indirectly,
                               through the Site's technology. Here is how we collect that information:
                           </p>
                           <p><span className="underline">Information We Collect Directly</span>. We collect
                               information, including PII, provided by you when you provide such information on the
                               Site, for example,
                               when you fill out a form. We also collect data to facilitate meaningful debate among
                               users of the Site and to display such poll results to the other
                               users. Information, which may include PII, is directly collected when a user provides
                               data in the My Profile or Demographics section of the Site and
                               when users cast votes on polls, enter any comments on such polls, or use other features
                               of the Site, although we will not display PII to other users.
                           </p>
                           <p>You do not have to provide PII to us, but when you withhold that information, certain
                               features or functions of the Site may be limited. OCOV keeps all
                               directly collected information confidential and will only use the information for the
                               particular purpose stated at the point of collection or set forth in
                               this Privacy Policy. OCOV will seek your specific permission for any additional use. You
                               may choose to have this information removed at any time. OCOV
                               will never barter, trade, or sell access to your information without your specific
                               consent.
                           </p>
                           <p>
                               <span className="underline">Information We Collect Indirectly</span>. We collect
                               information, including PII, using indirect means, when you
                               interact the Site. This information may include, for example, browser and device
                               information, application usage data, demographic information, geographic
                               location information, and statistical and aggregated information. If any information is
                               combined with PII we will treat the combined information as PII.
                           </p>
                           <p>
                               We use persistent and session cookies and other tracking technologies to: (a) store your
                               username and password; (b) analyze the usage of the Site; and (c)
                               control the advertising displayed on the Site. A persistent cookie remains on your
                               computer after you close your browser so that it can be used by your
                               browser on subsequent visits to the Site. A session cookie is temporary and disappears
                               after you close your browser. You can reset your browser to refuse
                               all cookies or to indicate when a cookie is being sent. However, some features of the
                               Site may not function properly if the ability to accept cookies is
                               disabled. We also use tracking services such as Google Analytics, which use cookies to
                               collect anonymous traffic data. By using the Site, you consent to
                               the processing of data about you in the manner and for the purposes set forth above. As
                               we adopt additional technology, we may also gather additional
                               information through other methods.
                           </p>
                           <p>We automatically receive and record information from your device or web browser when you
                               interact with the Site. For example, we collect your internet protocol (IP) address,
                               mobile device ID, time zone setting, language preference, operating system, platform,
                               internet service provider (ISP), and links you have clicked on or shared with others,
                               including through email and social media. If you sign up for an account, we also create a
                               user ID that indirectly identifies you. We use this information to collect data about
                               your activities through the Site. We associate information that indirectly identifies you
                               with your account (if you have one) and other information we have collected about
                               you.</p>
                           <p>We may collect different types of information about your location, including general
                               information (e.g., IP address, zip code) and more specific information (e.g., GPS-based
                               functionality on mobile devices used to access the Site), and may use that information to
                               customize the Site with location-based information and features. If you access the Site
                               through a mobile device and you do not want your device to provide us with
                               location-tracking information, you can disable the GPS or other location-tracking
                               functions on your device, provided your device allows you to do this.</p>
                           <p>When we conduct research or carry out surveys and you participate in our research or
                               surveys, we may collect sensitive personal data about you, for example, information about
                               your racial or ethnic origin, political opinions, religious or philosophical beliefs, sex
                               life or sexual orientation. By participating in our research or surveys you consent to
                               our collection, use and/or sharing of this data.</p>
                           <p>We analyze and aggregate personal data we collect, such as the number and frequency of
                               your visits to the Site, to produce anonymized metrics and statistical information that
                               does not identify you as an individual. We combine information about you with information
                               about other users to create audience segments of aggregated data, for example,
                               statistical, demographic and usage data. If we combine or connect aggregated data with
                               your personal data, we treat the combined data as personal data and deal with it in
                               accordance with this Privacy Policy.</p>
                           <p>OCOV may allow third parties to serve advertisements on the Site and may use other third
                               parties to help us collect and analyze information about your use of the Site, generate
                               aggregate site usage statistics and provide content sharing services to support the Site.
                               These third parties may also use cookies and similar technologies to collect similar
                               non-personal information about your use of the Site. OCOV does not control the third
                               parties' use of such technologies and their use is governed by those parties' privacy
                               policies. For more information about third-party ad networks that use these technologies,
                               and to opt-out of their collection of information, see <a className="privacyLink"
                                   href="https://www.networkadvertising.org/managing/opt_out.asp"
                                   target="_blank" rel="noopener">https://www.networkadvertising.org/managing/opt_out.asp</a> and <a
                                   className="privacyLink" href="https://www.aboutads.info/consumers"
                                   target="_blank" rel="noopener">https://www.aboutads.info/consumers</a>.</p>
                       </li>

                       <li>
                           <span>What We Do With The Data</span>
                           <p>We use the data we collect to provide, maintain, and improve the Site. We also use the
                               data we collect to send you technical notices, updates, security alerts and support and
                               administrative messages and to respond to your comments, questions and customer service
                               requests; to communicate with you about products, services, offers, and events offered by
                               OCOV and others, and provide news and information we think will be of interest to you; to
                               personalize and improve the Site and provide advertisements, content or features that
                               match user profiles or interests; monitor and analyze trends, usage and activities in
                               connection with the Site and personalize and improve the Site; to detect, investigate and
                               prevent fraudulent and illegal activities and protect the rights and property of OCOV and
                               others; and carry out any other purpose described to you at the time the data was
                               collected.</p>
                       </li>
                       <li>
                           <span>How We Share Your Information</span>
                           <p><span className="underline">Personally Identifiable Information.</span> OCOV may share
                               your PII with third parties for the purpose of providing you with the best experience
                               when you use the Site or with third parties who offer combined services with OCOV. If we
                               provide PII to third parties, those third parties will be bound by this Privacy Policy.
                               Also, we may store your PII in locations outside our direct control, for example, on
                               servers of our hosting providers. We combine information about you from third party
                               sources with information we hold about you to create a user profile, which will help us
                               make our sales and marketing efforts more relevant to you and to personalize and improve
                               our marketing/sales&nbsp;campaigns and website experiences.</p>
                           <p>As we develop our business, we may buy or sell assets or consider business offerings. User
                               information is generally one of the transferred business assets in these types of
                               transactions. We may transfer or assign such information in the course of corporate
                               divestitures, mergers, or dissolution. We may also share or disclose your information
                               with your consent. In addition, OCOV may disclose your PII, if required, to do so by law
                               or subpoena or if we believe that it is reasonably necessary to comply with a law,
                               regulation or legal request; to protect the safety of any person; to address fraud,
                               security or technical issues; or to protect OCOV's rights or property.</p>
                           <p><span className="underline">Non-Personally Identifiable Information</span>. We may
                               aggregate and/or anonymize information collected on the Site so that the information does
                               not identify you. We may use that aggregated, anonymized, and other non-PII for any
                               purpose, including, and without limitation, for marketing, research and marketing
                               purposes, and our use and disclosure of such information is not subject to any
                               restrictions under this Privacy Policy.</p>
                       </li>
                       <li>
                           <span>How We Protect Your Information</span>
                           <p>OCOV uses commercially reasonable physical and technical safeguards to preserve the
                               integrity and security of your PII. We cannot, however, ensure or warrant the security of
                               any information you transmit to OCOV or guarantee that your information may not be
                               accessed, disclosed, altered, or destroyed by breach of any of our physical or managerial
                               safeguards. In the event that PII is compromised as a result of a breach of security,
                               OCOV will promptly notify those persons whose PII has been compromised, in accordance
                               with the notification procedures set forth in this Privacy Policy, or as otherwise
                               required by applicable law.</p>
                       </li>
                       <li>
                           <span>Your Rights to Access, Correct and Limit Our Use of Your Personally Identifiable Information</span>
                           <p>You have the right to access the PII that we hold about you and to correct, update, amend,
                               suppress, delete or otherwise modify any PII where it is inaccurate unless we have to
                               keep the information for legitimate business or legal purposes. You may object to the use
                               or processing of your PII or withdraw consent to use your PII at any time. We may reject
                               requests that are unreasonably repetitive, require disproportionate technical effort,
                               risk the privacy of others, or would be extremely impractical.</p>
                           <p>We will obtain your consent before sharing your PII with any third party that is not
                               acting as an agent or vendor to perform tasks on our behalf or to use the information for
                               a purpose materially different from the purpose for which it was initially collected or
                               subsequently authorized by you. An exception to this will be where we are required to
                               respond to lawful requests by public authorities, including to meet national security or
                               law enforcement requirements.</p>
                       </li>
                       <li>
                           <span>Children's Privacy</span>
                           <p>OCOV does not knowingly contact children to participate in opinion surveys or for any
                               other purpose. Protecting the privacy of children is especially important. For that
                               reason, we do not collect or maintain information on the Site from those we actually know
                               are under 13, and no part of the Site is designed to attract anyone under 13.</p>
                       </li>
                       <li>
                           <span>Links to Other Sites</span>
                           <p>We are not responsible for the practices employed by websites or applications linked to or
                               from the Site , nor the information or content contained therein. Please remember that
                               when you use a link to go from the Site to another website, our Privacy Policy is no
                               longer in effect. Your browsing and interaction on any other website, including those
                               that have a link on our website, is subject to that website's own rules and policies.</p>
                       </li>
                       <li>
                           <span>Your California Privacy Rights</span>
                           <p>California law permits users who are California residents to request and obtain from us
                               once a year, free of charge, a list of the third parties to whom we have disclosed their
                               PII (if any) for their direct marketing purposes in the prior calendar year, as well as
                               the types of PII disclosed to those parties. If you are a California resident and would
                               like to request this information, please submit your request in an email to <a
                                   href="mailto:support@ocov.us">support@ocov.us</a>.</p>
                           <p>California residents may opt out of the “sale” of their personal data. California law
                               broadly defines “sale” in a way that may include allowing third parties to receive
                               certain data, such as cookies, IP address, and/or browsing behavior, to deliver targeted
                               advertising on the Site or other sites and applications. Advertising, including targeted
                               advertising, enables us to provide you certain content for free and allows us to provide
                               you content relevant to yourself. If you would like to opt out of our use of your
                               personal data for such purposes, you may do so by contacting us at&nbsp;<a
                                   href="mailto:support@ocov.us">support@ocov.us</a>. Please note that we do not
                               knowingly sell the personal data of minors under 16 years of age without legally-required
                               affirmative authorization.</p>
                           <p>Finally, under California Civil Code sections 1798.83- 1798.84, California residents are
                               entitled to ask for a notice identifying the tags of personal customer data which we
                               share with our affiliates and/or third parties for their own direct marketing purposes,
                               and providing contact information for such affiliates and/or third parties. If you are a
                               California resident and would like a copy of this notice, please submit a written request
                               to <a href="mailto:support@ocov.us">support@ocov.us</a></p>
                       </li>
                       <li>
                           <span>Changes to Our Privacy Policy</span>
                           <p>If we change our privacy policies and procedures, we will post those changes on this page
                               to keep you aware of what information we collect, how we use it and under what
                               circumstances we may disclose it. Changes to this Privacy Policy are effective when they
                               are posted on this page.</p>
                           <p>If you have any questions about our practices or this privacy policy, please contact us
                               at <a href="mailto:support@ocov.us">support@ocov.us</a>.</p>
                       </li>


                   </ol>

               </section>

           </main>
       </section>
       <Footer />
   </>
  )
}
