import { useState, useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useVenti } from 'venti'
import { isEqual } from 'lodash'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import { FormControl, InputLabel, Input, CircularProgress } from '@material-ui/core'
import Divider from '@mui/material/Divider'
import YesNo from '../Modals/YesNo'
import theme from '../../config'
import { saveMe } from '../../services/client'
import { setItemAsyncStorage } from '../../services/helper'
import { userLogoutAction } from '../../store/user'
import './MyProfile.css'

export default function MyProfile() {
    const history = useHistory()
    const ventiState = useVenti()
    const user = ventiState.get(theme.storageKeys.user, {})
    const authToken = ventiState.get(theme.storageKeys.authToken, {})
    const { storageKeys } = theme
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingUser, setLoadingUser] = useState<boolean>(false)
    const [yesNoOpen, setYesNoOpen] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [initialState, setInitialState] = useState({
        username: '',
        avatar: ''
    })
    const [myProfileState, setMyProfileState] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }), {
            username: '',
            avatar: ''
        })
    const [userdMessage, setUserMessage] = useState({
        message: '',
        isError: false
    })
    const [passwordMessage, setPasswordMessage] = useState({
        message: '',
        isError: false
    })

    useEffect(() => {
        setInitialState({
            username: user.username,
            avatar: user.avatar
        })
        setMyProfileState({
            username: user.username,
            avatar: user.avatar
        })
    }, [user])

    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const handleImagePicker = async (e: any) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        setMyProfileState({ 'avatar': base64 })
    }

    const handleUpdateUser = () => {
        setLoadingUser(true)
        setUserMessage({
            message: '',
            isError: false
        })
        saveMe({ username: String(myProfileState.username).trim(), avatar: myProfileState.avatar }, authToken)
            .then((responce) => {
                const user = {
                    ...responce,
                    isLoggedIn: true,
                    token: null,
                    userName: null,
                    tokenExp: null,
                }

                setItemAsyncStorage(storageKeys.user, JSON.stringify(user))
                ventiState.set(theme.storageKeys.user, user)
                setUserMessage({
                    message: 'Profile updated',
                    isError: false
                })
                setLoadingUser(false)
            })
            .catch((err) => {
                setUserMessage({
                    message: 'Display Name in use',
                    isError: true
                })
                setLoadingUser(false)
            })
    }

    const enabledSaveButton = myProfileState.username.length >= 3 && !isEqual(initialState, myProfileState)

    const passedAll = !theme.validation.passwordRequirements.filter(
        (requirement) => !requirement.regex.test(password)
    ).length

    const enabledResetButton = passedAll && currentPassword.length > 8

    const handleResetPassword = () => {
        setLoading(true)
        setPasswordMessage({
            message: '',
            isError: false
        })
        saveMe({ current_password: currentPassword, new_password: password }, authToken)
            .then(() => {
                setPassword('')
                setCurrentPassword('')
                setPasswordMessage({
                    message: 'Your password has been changed',
                    isError: false
                })
                setLoading(false)
            })
            .catch((err) => {
                setPasswordMessage({
                    message: err.data || 'Current Password is incorrect',
                    isError: true
                })
                setLoading(false)
            })
    }

    const logOut = () => {
        userLogoutAction()
        history.push('/')
    }

    return (
        <>
            <YesNo open={yesNoOpen} setOpen={setYesNoOpen} text='Are you sure you want to logout?' confirm={() => logOut()} />
            <div className='profile-wrapper'>
                <div className='profile-photo-wrapper'>
                    <div className='profile-photo-block'>
                        <Avatar
                            alt='Remy Sharp'
                            src={myProfileState.avatar}
                            sx={{ width: 72, height: 72, marginRight: 2 }}
                        />
                        <p className='profile-title'>MEMBER SINCE {new Date(user.insert_time || new Date()).getFullYear()}</p>
                    </div>
                    <Button
                        sx={{ '.MuiButton-root': { width: 100 } }}
                        variant='text'
                        component='label'
                        className='profile-change-button'
                        color='error'
                    >
                        Change
                        <input
                            onChange={(e) => handleImagePicker(e)}
                            hidden
                            accept='image/*'
                            multiple
                            type='file'
                            name='myImage'
                        />
                    </Button>
                </div>
                <div className='profile-block-wrapper'>
                    <p className='profile-title'>USER SETTINGS</p>

                    <FormControl className='profile-form-input'>
                        <InputLabel htmlFor='username'>User name</InputLabel>
                        <Input
                            id='username'
                            value={myProfileState.username}
                            onChange={(e) => setMyProfileState({ 'username': e.target.value })}
                        />
                    </FormControl>
                    <FormControl className='profile-form-input'>
                        <InputLabel htmlFor='email'>Email</InputLabel>
                        <Input
                            id='email'
                            value={user.email}
                            disabled
                        />
                    </FormControl>
                    <Button
                        type='submit'
                        variant='contained'
                        color='error'
                        className='profile-form-button'
                        onClick={handleUpdateUser}
                        disabled={!enabledSaveButton || loadingUser}
                    >
                        {loadingUser ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                    {userdMessage.message && <p className={`message ${userdMessage.isError ? 'error' : 'success'}`}>{userdMessage.message}</p>}
                </div>
                <div className='profile-block-wrapper'>
                    <p className='profile-title'>PASSWORD</p>
                    <FormControl className='profile-form-input'>
                        <InputLabel htmlFor='password'>Password</InputLabel>
                        <Input
                            id='password'
                            type='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div>
                            {passedAll ? (
                                <p className='change-password-title'>{`\u2713 All password requirements have been met`}</p>
                            ) : (
                                <>
                                    <p className='change-password-title'>
                                        Your password must meet the following requirements...
                                    </p>

                                    {theme.validation.passwordRequirements.map((requirement, i) => {
                                        let icon = `\u2022`
                                        const pass = requirement.regex.test(password)
                                        if (pass) icon = `\u2713`
                                        return (
                                            <p
                                                key={requirement.key + i}
                                                className={`requirement-text ${pass && 'pass-color'}`}
                                            >{`${icon} ${requirement.label}`}</p>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                    </FormControl>
                    <FormControl className='profile-form-input'>
                        <InputLabel htmlFor='currentPassword'>Current Password</InputLabel>
                        <Input
                            id='currentPassword'
                            type='password'
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </FormControl>
                    <Button
                        type='submit'
                        variant='contained'
                        color='error'
                        className='profile-form-button'
                        onClick={handleResetPassword}
                        disabled={!enabledResetButton || loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Reset'}
                    </Button>
                    {passwordMessage.message && <p className={`message ${passwordMessage.isError ? 'error' : 'success'}`}>{passwordMessage.message}</p>}
                    <Divider sx={{ width: '100%', marginTop: 2, borderWidth: 1 }} />
                    <Button
                        type='submit'
                        variant='outlined'
                        color='error'
                        className='profile-form-button'
                        onClick={() => setYesNoOpen(true)}
                    >
                        Sign Out
                    </Button>
                </div>
            </div>
        </>
    )
}