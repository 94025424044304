import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import MarkdownPreview from '@uiw/react-markdown-preview'
import { useWindowDimensions } from '../../services/utils'
import './Info.css'

interface Props {
    open: boolean
    setOpen: any
    text: string
    setText: any
}

export default function Info(props: Props) {
    const { width } = useWindowDimensions()

    const handleClose = () => {
        props.setText('')
        props.setOpen(false)
    }

    return (
        <div>
            <Dialog
                sx={{
                    '.MuiPaper-root': {
                        margin: '0 10px',
                        width: width > 762 ? 700 : 300
                    }
                }}
                open={props.open}
                onClose={handleClose}
            >
                <div className='info-wrapper'>
                    <Tooltip title="Close">
                        <IconButton
                            size='large'
                            color='inherit'
                            aria-label='close'
                            onClick={() => handleClose()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <MarkdownPreview style={{ maxWidth: "100%" }} source={props.text} />
                </div>
            </Dialog>
        </div>
    )
}