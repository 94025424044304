const theme = {
	debug: {
		enabled: false,
		token: '6mDavEr0FL90IvZhRImyCBnycfs-GpvXnclekGOB6T2FldOdIGHzN-2-NyO4DcefDfY3P8c4sGzpS9cqplPeBn1XQgOk523NVsyJ6Wq8X3pGxMCpvZ4jC6nM7FlHHJz2gm5hN0b4qWSTyHan1uNu4VkljsRFGDtT3VmKwOaWZ8dxgW0Gd1jzwmE4HUHGVEbEPUdjV-dM9QFXnzZI0H007K9ev9yeXIHYiQDajQIzhivxH_4Ukbk_lyb2qKMfw25EH0hbQ-CvmmpJevOPzMLrukgL04fpDLlMuFMNTeYqL_r-g1oRsUNYS1UvLk73ksF6ViJvISnPSHruWqqbSthq_A-LWsY2u56JFPqDWI5FDCa4VWL7l8a91v1m8ajsxo-g4vdT82YHVB5c5_8-ARI1fMNiDA7tDEpZVpb9Ta9Q1jeClbxwA1AIg8-BWcnIgs0t6-SKFydwMxYuvaEfHvvxzz3iHBuEZMVhGELkVsdnZB5Mr4b5GxzC_zIkrSPluUa1',
		loanId: '{183663a9-450b-4f67-afc3-06ac0d9c30dc}',
		user: {
			role: 0,
			email: 'francsico@thebigpos.com',
			phone: ''
		}
	},
	api: {
		host: {
			// dev: "http://localhost:5000",
			dev: "https://api.ocov.us",
			// dev: "https://api-staging.ocov.us",
			production: process.env.REACT_APP_API_HOST || "https://api.ocov.us"
		}
	},
	youtube: {
		url: 'https://www.googleapis.com/youtube/v3/search',
		api_key: 'AIzaSyBhLUZgfQkhJN6yHnUdowdrEGUgA1q3xlc',
		channel_id: 'UC2d0q2XBKTckZLz1a6hshKw'
	},
	categoryLimit: 20,
	validation: {
		userName: 3,
		passwordLength: 8,
		passwordRegex: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
		passwordRequirements: [
			{
				key: "length",
				label: "Contains a minimum of 8 characters",
				regex: /^.{8,}$/,
			},
			{
				key: "upper",
				label: "Contains 1 Uppercase Letter (A-Z)",
				regex: /^(?=.*[A-Z]).+$/,
			},
			{
				key: "lower",
				label: "Contains 1 Lowercase Letter (a-z)",
				regex: /^(?=.*[a-z]).+$/,
			},
			{
				key: "number",
				label: "Contains 1 Number (0-9)",
				regex: /^(?=.*[0-9]).+$/,
			},
			{
				key: "special",
				label: "Contains 1 Special Character (!@#$&*)",
				regex: /^(?=.*[!@#$&*]).+$/,
			},
		],
	},
	authWrapperLogoWidth: 980,
	google: {
		apiKey: "AIzaSyAcYNnul7CllP8ZJkpDSv0pnkacBqxuMZc",
		places: {
			url: "https://eas-cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api"
		}
	},
	storageKeys: {
		user: '@ocov:user',
		authToken: '@ocov:token',
		user_list: '@ocov:user_list'
	},
	contact_us: {
		email: 'support@ocov.us',
		subject: 'Contact Us'
	},
	uris: {
		privacy: 'https://ocov.us/privacy-policy?app=true',
		terms: 'https://ocov.us/terms?app=true'
	},
	photos: {
		dir: 'ocov'
	},
}

export default theme
