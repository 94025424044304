export const socialIssues = [
    { value: 'conservative', name: 'Conservative' },
    { value: 'moderate', name: 'Moderate' },
    { value: 'liberal', name: 'Liberal' },
    { value: 'other', name: 'Other' }
]

export const politicalParty = [
    { value: 'democrat', name: 'Democrat' },
    { value: 'republican', name: 'Republican' },
    { value: 'independent', name: 'Independent' },
    { value: 'libertarian', name: 'Libertarian' },
    { value: 'green', name: 'Green' },
    { value: 'no_affiliation', name: 'No Affiliation' },
    { value: 'other', name: 'Other' }
]

export const educationData = [
    { value: 'high_school', name: 'High School' },
    { value: 'associates', name: 'Associates' },
    { value: 'bachelors', name: 'Bachelors' },
    { value: 'masters', name: 'Masters/Graduate' },
    { value: 'phd', name: 'Doctorate (PhD/MD)' }
]

export const genderData = [
    { value: 'male', name: 'Male' },
    { value: 'female', name: 'Female' },
    { value: 'other', name: 'Other' }
]

export const fiscalData = [
    { value: 'conservative', name: 'Conservative' },
    { value: 'moderate', name: 'Moderate' },
    { value: 'liberal', name: 'Liberal' },
    { value: 'other', name: 'Other' }
]

export const economicIdeology = [
    { value: 'sapitalist', name: 'Capitalist' },
    { value: 'socialist', name: 'Socialist' },
    { value: 'communist', name: 'Communist' },
    { value: 'other', name: 'Other' }
]

export const incomeData = [
    { value: 'less 25', name: '< $25,000' },
    { value: '25-49', name: '$25,000 - $49,999' },
    { value: '50-99', name: '$50,000 - $99,999' },
    { value: '100-149', name: '$100,000 - $149,999' },
    { value: 'more 150', name: '> $150,000' }
]

export const raceData = [
    { value: 'black', name: 'African / Black' },
    { value: 'asian', name: 'Asian' },
    { value: 'white', name: 'Caucasian / White' },
    { value: 'latino', name: 'Latino / Hispanic' },
    { value: 'eastern', name: 'Middle Eastern' },
    { value: 'american', name: 'Native American' },
    { value: 'other', name: 'Other' }
]

export const religionData = [
    { value: 'agnostic', name: 'Agnostic' },
    { value: 'atheist', name: 'Atheist' },
    { value: 'christian', name: 'Christian' },
    { value: 'hindu', name: 'Hindu' },
    { value: 'jewish', name: 'Jewish' },
    { value: 'muslim', name: 'Muslim' },
    { value: 'other', name: 'Other' }
]

export const sexualData = [
    { value: 'heterosexual', name: 'Heterosexual' },
    { value: 'gay', name: 'Gay/Lesbian' },
    { value: 'bisexual', name: 'Bisexual' },
    { value: 'other', name: 'Other' }
]

export const USCitizen = [
    { value: 'yes', name: 'Yes' },
    { value: 'no', name: 'No' },
]

export const registeredToVote = [
    { value: 'yes', name: 'Yes' },
    { value: 'no', name: 'No' },
]

export const militaryService = [
    { value: 'yes', name: 'Yes' },
    { value: 'no', name: 'No' },
]

export const socialIssuesHelpText = `The government's impact on issues relating to moral, ethical, or individual decisions or rights.`

export const economicIdeologyHelpText = `Your perspective on how the economy should be operated and controlled.`