import { useEffect, useReducer, useState } from 'react'
import { FormControl, InputLabel, Input } from '@material-ui/core'
import Button from '@mui/material/Button'
import { isEmailValid } from '../../services/helper'
import Logo from '../../images/ocov-version-2-750.png'
import { resetPassword } from '../../services/client'
import './ResetPassword.css'
import theme from '../../config'

interface Props {
	setForm: any,
	handleClose: any
}

function ForgotPassword(props: Props) {

	useEffect(() => {
		const qs = window.location.search.replace('?', '').split('&')
		qs.forEach(q => {
			const parts = q.split('=')
			if (parts[0] === 'email') setEmail(parts[1])
			else if (parts[0] === 'token') setToken(parts[1])
		})
	},[])

	const [message, setMessage] = useState({ isError: false, messageText: '', isSuccess: false })
	const [formInput, setFormInput] = useReducer(
		(state: any, newState: any) => ({ ...state, ...newState }),
		{
			password: '',
		}
	)
	const [email, setEmail] = useState('')
	const [token, setToken] = useState('')

	const enabledSignInButton = isEmailValid(email)

	const handleSubmit = (evt: any) => {
		evt.preventDefault()

		resetPassword(email, token, formInput.password)
			.then(() => {
				setMessage({
					isError: false,
					messageText: 'Your password has been reset',
					isSuccess: true
				})
				setFormInput({ password: "" })
				return Promise.resolve()
			})
			.catch((error) => {
				setMessage({
					isError: true,
					messageText: error.message,
					isSuccess: false
				})
			})
	}

	const handleInput = (evt: any) => {
		if (message.isError) setMessage({ isError: false, messageText: '', isSuccess: false })
		const name = evt.target.id
		const newValue = evt.target.value
		setFormInput({ [name]: newValue })
	}

	const passedAll = !theme.validation.passwordRequirements.filter(
		(requirement) => !requirement.regex.test(formInput.password)
	).length

	return (
		<div className="auth-wrapper">
			<div className="auth-content-wrapper">
				<img className="auth-logo" src={Logo} alt="logo" />
				<h1 className="auth-title">Set Your New Password</h1>
				<form onSubmit={handleSubmit} className="auth-form-wrapper">
					<FormControl className="auth-form-input">
						<InputLabel htmlFor="password">New Password</InputLabel>
						<Input
							id="password"
							type="password"
							defaultValue={formInput.password}
							onChange={handleInput}
						/>
						<div>
							{passedAll ? (
								<p className="change-password-title">{`\u2713 All password requirements have been met`}</p>
							) : (
								<>
									<p className="change-password-title">
										Your password must meet the following requirements...
									</p>

									{theme.validation.passwordRequirements.map((requirement, i) => {
										let icon = `\u2022`;
										const pass = requirement.regex.test(formInput.password);
										if (pass) icon = `\u2713`;
										return (
											<p
												key={requirement.key + i}
												className={`requirement-text ${pass && "pass-color"}`}
											>{`${icon} ${requirement.label}`}</p>
										);
									})}
								</>
							)}
						</div>
					</FormControl>
					<Button
						type="submit"
						variant="contained"
						color="error"
						className="auth-form-button"
						disabled={!enabledSignInButton}
					>
						Reset Password
					</Button>
				</form>

				{message && <p className={`message ${message.isError ? 'error' : 'success'}`}>{message.messageText}</p>}

				<div className="auth-link-wrapper">
					<p>{ !message.isSuccess ? 'I think I remember.' : 'Return to' }
						<Button onClick={() => props.setForm("login")}>
							Sign In
						</Button>
					</p>
				</div>
			</div>
		</div>
	)
}

export default ForgotPassword