import { useState, useEffect, useRef } from 'react'
import EmojiPicker from 'emoji-picker-react'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import Button from '@mui/material/Button'
import { isMobile } from 'react-device-detect'
import './Comment.css'

interface Props {
    title: string
    placeholder?: string
    isReply: boolean
    commentName: string
    text: string
    textChange: any
    handlePostComment: any
    id: number | null
    authToken: string
    reply?: any
    commentId?: number | null
    replyId?: number | null
    resetId?: any
}

export default function Comment(props: Props) {
    const emojiRef = useRef<any>(null)
    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                emojiRef?.current &&
                !emojiRef?.current?.contains(event.target)
            ) {
                setShowEmojiPicker(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [emojiRef])

    return (
        <>
            <div ref={emojiRef} className={`comment-wrapper ${(props.commentId && 'comment')} ${(props.replyId && 'reply')}`}>
                <Input
                    multiline
                    minRows={1}
                    placeholder={props.placeholder || 'Write a comment...'}
                    inputProps={{ maxLength: 500 }}
                    onChange={(e) => props.textChange(e.target.value)}
                    onFocus={() => setShowEmojiPicker(false)}
                    value={props.text}
                    endAdornment={
                        !isMobile &&
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                >
                                    <SentimentSatisfiedAltIcon />
                                </IconButton>
                            </InputAdornment>
                    }
                />
                <p className='comment-counter'>{props.text.length} of 500</p>
                {showEmojiPicker &&
					<div className='comment-emoji-wrapper'>
						<EmojiPicker
							width={300}
							height={350}
							onEmojiClick={(e) => props.textChange(props.text + e.emoji)}
						/>
					</div>
                }
                {(!!props.text.length || props.reply) && <div className='comment-buttons-wrapper'>
					<Button
						type='submit'
						variant='outlined'
						color='error'
						className='comment-form-button'
						onClick={() => {
                            props.reply && props.resetId(null);
                            props.textChange('');
                        }}
					>
						Cancel
					</Button>
					<Button
						type='submit'
						variant='contained'
						color='error'
						className='comment-form-button'
						onClick={() => props.handlePostComment(props.id, props.text, props.authToken)}
						disabled={!props.text}
					>
						Comment
					</Button>
				</div>}
            </div>
        </>
    )
}