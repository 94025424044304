import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { useWindowDimensions } from '../../services/utils'
import './YesNo.css'

interface Props {
    open: boolean
    setOpen: any
    text: string
    confirm: () => void
}

export default function YesNo(props: Props) {
    const { width } = useWindowDimensions()

    const handleClose = () => {
        props.setOpen(false)
    }

    return (
        <div>
            <Dialog
                sx={{
                    '.MuiPaper-root': {
                        margin: '0 10px',
                        width: width > 762 ? 380 : 300
                    }
                }}
                open={props.open}
                onClose={handleClose}
            >
                <div className='yesno-wrapper'>
                    <p className='yesno-title'>{props.text}</p>
                    <div className='yesno-buttons-wrapper'>
                        <Button
                            type='submit'
                            variant='contained'
                            color='error'
                            className='yesno-form-button'
                            onClick={props.confirm}
                        >
                            Yes
                        </Button>
                        <Button
                            type='submit'
                            variant='outlined'
                            color='error'
                            className='yesno-form-button'
                            onClick={handleClose}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}