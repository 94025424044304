import axios from 'axios'
import theme from '../config'
import { state } from 'venti'
import { isEmpty } from 'lodash'
import { DebateFormData } from "../components/DebateForm/DebateForm"
const host = process.env.NODE_ENV !== "production" ? theme.api.host.dev : theme.api.host.production

type createAccountType = {
	displayName: string,
	email: string,
	password: string
}

type ReplyData ={
	comment_id: number,
	message: string,
	in_reply_to_user_id: number,
	reply_id?: null | number
}

const _setAuthHeader = (accessToken?: string) => ({ headers: { 'Authorization': `Bearer ${accessToken || state.get(theme.storageKeys.authToken)}` } })

/**
 * @param {string} username
 * @param {string} password
 * @param {string} deviceType
 * @returns {Promise}
 */
export function authenticate(username: string, password: string, deviceType = "") {
	return post(`${host}/authenticate`, {
		username,
		password,
		deviceType
	})
}

/**
 * Get authenticated user data
 * @returns {Promise}
 */
export function getMe(authToken: string) {
	return get(
		`${host}/me`,
		_setAuthHeader(authToken)
	)
}

/**
 * Get authenticated user data
 * @returns {Promise}
 */
export function subscribe(email: string) {
	return post(
		`${host}/subscribe/blog`,
		{ email }
	)
}

export function getTrendingPolls() {
	return get(`${host}/polls?activeOnly=1&category=0`, false)
}

/**
 * Get articles
 */
export function getArticles() {
	return get(`${host}/articles?status=active`, false)
}

/**
 * Get article by id
 * @param id
 */
export function getArticle(id: number) {
	return get(`${host}/articles/${id}`, false)
}

/**
 * Get article by slug
 * @param slug
 */
export function getArticleBySlug(slug: any) {
	return get(`${host}/articles/${slug}`, false)
}

/**
 * @return {Promise}
 */
export function addArticleComment(id: number | null, message: any, authToken: string, type?: string) {
	let data = { message, article_id: id }

	return post(`${host}/comments`, data, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function likeArticleComment(id: number, value: number, authToken: string) {
	return post(`${host}/comments/${id}/like`, { like_value: value }, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function likeArticleReplyComment(id: number, value: number, authToken: string) {
	return post(`${host}/replies/${id}/like`, { like_value: value }, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function replyToArticleComment(commentId: number, userId: number, replyId: null | number, message: string, authToken: string) {
	let data: ReplyData = {
		comment_id: commentId,
		message,
		in_reply_to_user_id: userId
	}

	if (replyId) {
		data.reply_id = replyId
	}

	return post(`${host}/replies`, data, _setAuthHeader(authToken))
}

/**
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export function get(url: string, config: any) {
	return axios.get(url, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function post(url: string, data: any, config = {}) {
	return axios.post(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function put(url: string, data: any, config = {}) {
	return axios.put(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function patch(url: string, data: any, config = {}) {
	return axios.patch(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export function remove(url: string, config: any) {
	return axios.delete(url, config).then(response => response?.data).catch(error => Promise.reject(error.response.data))
}

/**
 * @param name
 * @param data
 */
export function sendForm(name: string, data: DebateFormData) {
	return post(
		`${host}/forms`,
		{ ...data, name }
	)
}

/**
 * @return {Promise}
 */
export function getVideos() {
	return get(`${theme.youtube.url}?part=snippet&channelId=${theme.youtube.channel_id}&type=video&key=${theme.youtube.api_key}`, false)
}

/**
 * @param {String} username
 * @return {Promise}
 */
export function isUsernameInUse(username: string) {
	return get(`${host}/users/check?username=${username}`, false)
}

/**
 * @param {String} email
 * @return {Promise}
 */
export function isEmailInUse(email: string) {
	return get(`${host}/users/check?email=${email}`, false)
}

/**
 * @param {String} email
 * @return {Promise}
 */
export function forgotPassword(email: string) {
	return post(`${host}/me/forgotPassword`, { email })
}

export function resetPassword(email: string, token: string, password: string) {
	return post(`${host}/me/resetPassword`, { email, token, password})
}

/**
 * @return {Promise}
 */
export function createAccount(data: createAccountType, deviceType = '') {
	return post(`${host}/register`, {
		username: String(data.displayName).trim().toLowerCase(),
		email: data.email,
		password: data.password,
		deviceType
	})
}

/**
 * @return {Promise}
 */
export function checkSession(authToken: string) {
	return get(`${host}/session`,
		_setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function getDemographics(authToken: string) {
	return checkSession(authToken)
		.then(() => get(`${host}/me/demographics`,
			_setAuthHeader(authToken)))
}

/**
 * @return {Promise}
 */
export function getCategories(type = "polls") {
	return get(`${host}/categories?type=${type}`, false)
}

/**
 * @return {Promise}
 */
export function saveMe(data: any, authToken: string) {
	return patch(`${host}/me`, data, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function saveDemographics(data: any, authToken: string) {
	return patch(`${host}/me/demographics`, data, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function getNextCategoryChunk(id: number, offset = 0, limit = theme.categoryLimit) {
	// let GET_URL = `${host}/polls?activeOnly=1&category=${id}&limit=${limit}&offset=${offset}&noComments=1`

	// Get all posts only for Trending
	// if (String(id) === '0') {
	const GET_URL = `${host}/polls?activeOnly=1&category=${id}`
	// }

	return get(GET_URL, false)
}

/**
 * @return {Promise}
 */
export function pollFollow(id: number, authToken: string) {
	return post(`${host}/polls/${id}/follow`, {}, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function getPollData(pollId: number, authToken: string) {
	return get(`${host}/polls/${pollId}`, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function getPollBySlug(slug: string, authToken: string) {
	return get(`${host}/polls/${slug}`, !isEmpty(authToken) ? _setAuthHeader(authToken) : false)
}

/**
 * @return {Promise}
 */
export function vote(pollId: number, data: any, authToken: string) {
	return post(`${host}/polls/${pollId}/vote`, data, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function addComment(id: number | null, message: any, authToken: string, type?: string) {
	let data = { message, poll_id: id }

	return post(`${host}/comments`, data, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function likeComment(id: number, value: number, authToken: string) {
	return post(`${host}/comments/${id}/like`, { like_value: value }, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function likeReplyComment(id: number, value: number, authToken: string) {
	return post(`${host}/replies/${id}/like`, { like_value: value }, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function replyToComment(commentId: number, userId: number, replyId: null | number, message: string, authToken: string) {
	let data: ReplyData = {
		comment_id: commentId,
		message,
		in_reply_to_user_id: userId
	}

	if (replyId) {
		data.reply_id = replyId
	}

	return post(`${host}/replies`, data, _setAuthHeader(authToken))
}

/**
 * @return {Promise}
 */
export function searchPolls(value: string, authToken: string) {
	return get(`${host}/polls?activeOnly=1&query=${value}`, false)
}