import { Redirect } from "react-router-dom"
import { useVenti } from "venti"
import theme from "../config"

export default function PrivateRoute({ children }: any) {
	const ventiState = useVenti()
	const user = ventiState.get(theme.storageKeys.user, {})

	return (
		user && user.isLoggedIn ? (
			children
		) : (
			<Redirect
				to={`/`}
			/>
		)
	)
}
