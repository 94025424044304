import { useReducer, useState } from 'react'
import {
    TextField,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Button,
    FormHelperText,
} from '@mui/material'
import { isEmailValid } from '../../services/helper'
import { makeStyles } from "@mui/styles"
import { sendForm } from "../../services/client"
import Alert from "../Alert/Alert"

const useStyles = makeStyles((theme) => ({
    form: {
        borderRadius: 10,
        border: '1px solid #dadce0',
        padding: 30,
    },
    formTitle: {
        fontSize: 28,
        textAlign: 'left',
        paddingLeft: 10
    },
    formControl: {
        width: '100%',
        margin: '20px 0 0',
        textAlign: 'left'
    },
    selectEmpty: {
        marginTop: 20,
    },
    option: {
        textAlign: 'left'
    },
    radioButton: {
        marginLeft: 0
    },
    terms: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 20,
        marginLeft: 0,
        paddingLeft: 0
    },
    submitButton: {
        marginTop: 30,
        border: '5px solid transparent',
        transition: '0.5s',
        display: 'block',
        width: "100%",
        maxWidth: 218,
        margin: '0 auto',
        padding: '5px 0px',
        backgroundColor: '#940E15',
        fontWeight: 300,
        fontSize: 14,
        letterSpacing: 1,
        color: '#FFF',
        textAlign: 'center',
        textDecoration: 'none',
        boxSizing: 'border-box',
        '&:disabled': {
            background: '#cfcfcf !important',
        },
        borderRadius: 50
    },
    required: {
        fontSize: 16,
        color: '#940E15'
    }
}))

const defaultData = {
    first_name: '',
    last_name: '',
    email: '',
    topic: '',
    message: ''
}

export default function ContactForm() {

    const classes = useStyles()

    const [state, setState] = useState({
        submitting: false,
        submitted: false,
        error: false
    })

    const [formErrors, setFormErrors] = useState({
        first_name: false,
        last_name: false,
        email: false,
        topic: false,
        message: false
    })

    const [data, setData] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }), {}
    )

    const setErrors = (field: string, validation = '') => {
        setFormErrors(current => ({ ...current, [field]: !data[field] }))
    }

    const formDisabled = !data.first_name || !data.last_name || !data.email || !isEmailValid(data.email) || !data.political_affiliation || !data.message

    let emailValidationText
    if (formErrors.email) emailValidationText = 'Email Address is required'
    else if (data.email && !isEmailValid(data.email)) emailValidationText = 'Please enter a valid Email Address'
    else emailValidationText = ''

    const submitForm = async () => {
        setState({ submitting: true, submitted: false, error: false })
        try {
            await sendForm('contact', data)
            setState({ submitting: false, submitted: true, error: false })
            setData(defaultData)
        } catch (err) {
            setState({ submitting: false, submitted: false, error: true })
        }
    }

    return (
        <form className={classes.form}>
            <h3 className={classes.formTitle}>Contact Us <span className={classes.required}>(note: all fields are required)</span></h3>
            <FormControl className={classes.formControl}>
                <TextField label={'First Name'} value={data.first_name} onBlur={() => setErrors('first_name')} onChange={e => setData({ first_name: e.target.value })} fullWidth error={formErrors.first_name} helperText={formErrors.first_name && 'First Name is required'} />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField label={'Last Name'} value={data.last_name} onBlur={() => setErrors('last_name')} onChange={e => setData({ last_name: e.target.value })} fullWidth error={formErrors.last_name} helperText={formErrors.last_name && 'Last Name is required'} />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField type="email" label={'Email Address'} value={data.email} onBlur={() => setErrors('email')} onChange={e => setData({ email: e.target.value })} fullWidth error={formErrors.email || (!!data.email && !isEmailValid(data.email))} helperText={emailValidationText} />
            </FormControl>

            <FormControl className={classes.formControl} component="fieldset" error={formErrors.topic}>
                <FormLabel component="legend">What would you like to share with the OCOV Team?</FormLabel>
                <RadioGroup aria-label="political" name="gender1" value={data.political_affiliation} onChange={e => setData({ political_affiliation: e.target.value })}>
                    <FormControlLabel className={classes.radioButton} value="New Topics to Vote On" control={<Radio />} label="New Poll Topic" />
                    <FormControlLabel className={classes.radioButton} value="Questions About Debating" control={<Radio />} label="Debate Question" />
                    <FormControlLabel className={classes.radioButton} value="Need Assistance with the OCOV Mobile App" control={<Radio />} label="Mobile App Issue" />
                    <FormControlLabel className={classes.radioButton} value="Fact Checking a Blog Article" control={<Radio />} label="Fact Check" />
                    <FormControlLabel className={classes.radioButton} value="Wants to be a Beta Tester" control={<Radio />} label="Become a Beta Tester" />
                </RadioGroup>
                {formErrors.topic && <FormHelperText>Please choose one</FormHelperText>}
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField label={'Message'} value={data.message} onBlur={() => setErrors('message')} onChange={e => setData({ message: e.target.value })} multiline fullWidth error={formErrors.first_name} helperText={formErrors.first_name && 'First Name is required'} />
            </FormControl>
            <Button className={classes.submitButton} disabled={formDisabled || state.submitting} aria-label={'Submit Debate Form'} onClick={submitForm}>Submit</Button>
            <Alert title={state.submitted ? 'Submission Received!' : 'An Error Has Occurred'} message={state.submitted ? 'We appreciate you contacting OCOV. One of our colleagues will get back in touch with you soon! Have a great day!' : `We've encountered an unexpected error while attempted to submit the form. Please try again later.`} open={state.submitted || state.error} handleClose={() => setState({ submitting: false, submitted: false, error: false })} />
        </form>
    )
}