import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useVenti } from 'venti'
import { isEmpty } from 'lodash'
import PublishSubscribe from 'publish-subscribe-js'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarRateIcon from '@mui/icons-material/StarRate'
import ShareIcon from '@mui/icons-material/Share'
import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import Info from '../../components/Modals/Info'
import Share from '../../components/Modals/Share'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Comment from '../../components/Comment'
import CommentsBlock from '../../components/CommentsBlock'
import theme from '../../config'
import {
    vote, pollFollow, getPollBySlug, getPollData, addComment,
    likeComment, likeReplyComment, replyToComment
} from '../../services/client'
import './Poll.css'

export default function Poll() {
    const params: any = useParams()
    const history = useHistory()
    const ventiState = useVenti()
    const authToken = ventiState.get(theme.storageKeys.authToken, {})
    const user = ventiState.get(theme.storageKeys.user, {})
    const [state, setState] = useState<any>({
        poll: null,
        answerId: null,
        loadingAnswer: false,
        loading: false,
        commentsLoading: true,
        writeCommentView: false,
        isImageVisible: true,
        isVisible: false,
        isVoted: false,
        isFollowing: false,
        scrollDirection: '',
        comment: {},
        likeInProgress: false,
        likeReplyInProgress: false,
        dislikeInProgress: false,
        dislikeReplyInProgress: false,
    })
    const [pollId, setPollId] = useState<string>(params && params?.id)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isLoadingVote, setIsLoadingVote] = useState<boolean>(false)
    const [shareOpen, setShareOpen] = useState<boolean>(false)
    const [shareData, setShareData] = useState({
        link: '',
        name: ''
    })
    const [commentText, setCommentText] = useState<string>('')
    const [replyText, setReplyText] = useState<string>('')
    const [commentId, setCommentId] = useState<number | null>(null)
    const [replyId, setReplyId] = useState<number | null>(null)
    const [infoOpen, setInfoOpen] = useState<boolean>(false)
    const [infoText, setInfoText] = useState<string>('')

    useEffect(() => {
        if (!pollId) {
            history.push('/')
        }
    }, [])

    useEffect(() => {
        pollId && getPollBySlug(pollId, authToken)
            .then((poll) => {
                let answer

                if (poll.voted) {
                    answer = poll.answers.find((item: any) => item.myVote === true)
                }

                setState({
                    ...state,
                    poll: poll,
                    answerId: (answer && answer.id) || '',
                    isFollowing: !!poll.isFollowing,
                    isVoted: !!poll.voted
                })
                setIsLoading(false)

            })
            .catch(err => console.log('Error: ', err))
    }, [pollId])

    useEffect(() => {
        if (state.isVoted && !isEmpty(authToken) && state.answerId) handleVote()
    }, [state.answerId])

    const handleVote = () => {
        if (isEmpty(authToken)) {
            PublishSubscribe.publish('SHOW_LOGIN')
        } else {
            const poll = { ...state.poll }
            const answer = poll.answers.find((item: any) => item.id === state.answerId)

            if (!answer) {
                return;
            }

            setIsLoadingVote(true)
            answer.myVote = true

            setState({ ...state, loading: true })

            return vote(poll.id, { answer: answer.name }, authToken)
                .then(() => {
                    return getPollData(poll.id, authToken)
                        .then((data) => {
                            data.results = data.results ? data.results : poll.results
                            // updatePollData &&
                            //     updatePollData({
                            //         voted: true, answers: poll.answers, results: data.results
                            //     })
                            setState({ ...state, isVoted: true, poll: { ...poll, answers: poll.answers, results: data.results } })
                            setIsLoadingVote(false)
                        })
                        .catch(err => console.log('Error: ', err))
                })
                .catch((err) => {
                    if (err && err.message) {
                        console.log('Error: ', err)
                    }
                })
        }
    }

    const setAnswer = (answer: any, isVoted: boolean) => {
        setState({ ...state, answerId: answer.id })
    }

    const getResults = (answer: any, value: string) => {
        const poll = { ...state.poll }

        if (!poll.results) {
            if (value === 'width') {
                return '0%'
            } else {
                return ''
            }
        }

        const result = poll.results.find((item: any) => item.answer === answer.name)
        let check = {
            sum: 0,
            biggestValue: 0,
            answer: ''
        }

        if (!result) {
            return ''
        }

        for (let i = 0; i < poll.results.length; i++) {
            check.sum += Math.ceil(Number(poll.results[i].percentage))

            if (Math.ceil(Number(poll.results[i].percentage)) > check.biggestValue) {
                check.biggestValue = Math.ceil(Number(poll.results[i].percentage))
                check.answer = poll.results[i].answer
            }
        }

        if (check.sum > 100) {
            let diff = check.sum - 100
            check.biggestValue -= diff

            if (result.answer === check.answer) {
                result.percentage = check.biggestValue
            }
        }

        if (value === 'width') {
            return `${Math.ceil(Number(result.percentage))}%`
        }

        return `${Math.ceil(Number(result.percentage))}% people voted`
    }

    const handleFollow = () => {
        if (isEmpty(authToken)) {
            PublishSubscribe.publish('SHOW_LOGIN')
        } else {
            pollFollow(state.poll.id, authToken).then((res) => {
                setState({
                    ...state,
                    isFollowing: !!(res && res.following),
                    poll: { ...state.poll, isFollowing: !!(res && res.following) }
                })
            })
        }
    }

    const handlePostComment = () => {
        addComment(state.poll.id, String(commentText).trim(), authToken)
            .then((res) => {
                let comments = state.poll.comments.slice()
                res.user = {
                    username: user.username,
                    avatar: user.avatar
                }

                comments.unshift(Object.assign({}, res, { replies: [], num_likes: 0, num_dislikes: 0 }))
                setState({
                    ...state,
                    poll: { ...state.poll, comments: comments }
                })
                setCommentText('')
            })
    }

    const handlePostReply = (commentId: number, comment: any, replyId: null | number, resetId: any, resetMesssageText: any) => {
        replyToComment(commentId, (comment && comment.user.id) || user.id, Number(replyId), String(replyText).trim(), authToken)
            .then((res) => {
                let comments = state.poll.comments.slice()
                let comment = comments.find((item: any) => item.id === commentId)
                res.user = {
                    username: user.username,
                    avatar: user.avatar
                }
                comment.replies.push(Object.assign({}, res, { num_likes: 0, num_dislikes: 0 }))
                setState({
                    ...state,
                    poll: { ...state.poll, comments: comments }
                })
                resetId(null)
                resetMesssageText('')
            })
    }

    const handleLikeComment = (comment: any) => {
        const { poll } = state
        const like_value = comment.like_value === 1 ? 0 : comment.like_value === -1 ? 1 : 1
        if (!state.likeInProgress) {
            setState({ ...state, likeInProgress: true })
            likeComment(comment.id, like_value, authToken).then(() => {
                let commentsData = poll.comments.slice()
                let commentData = commentsData.find((item: any) => item.id === comment.id)
                commentData.num_likes = like_value === 1 ? Number(commentData.num_likes || 0) + 1 : Number(commentData.num_likes || 0) - 1
                commentData.num_dislikes = commentData.like_value === -1 ? Number(commentData.num_dislikes) - 1 : commentData.num_dislikes
                commentData.like_value = like_value
                setState({ ...state, likeInProgress: false, poll: { ...state.poll, comments: commentsData } })
            })
        }
    }

    const handleDislikeComment = (comment: any) => {
        const { poll } = state
        const like_value = comment.like_value === 1 ? -1 : comment.like_value === -1 ? 0 : -1

        if (!state.dislikeInProgress) {
            setState({ ...state, dislikeInProgress: true })
            likeComment(comment.id, like_value, authToken).then(() => {
                let commentsData = poll.comments.slice()
                let commentData = commentsData.find((item: any) => item.id === comment.id)
                commentData.num_likes = commentData.like_value === 1 ? Number(commentData.num_likes) - 1 : commentData.num_likes
                commentData.num_dislikes = like_value === -1 ? Number(commentData.num_dislikes || 0) + 1 : Number(commentData.num_dislikes || 0) - 1
                commentData.like_value = like_value
                setState({ ...state, likeInProgress: false, poll: { ...state.poll, comments: commentsData } })
            })
        }
    }

    const handleLikeReplyComment = (comment: any, reply: any) => {
        const { poll } = state
        const like_value = reply.like_value === 1 ? 0 : reply.like_value === -1 ? 1 : 1

        if (!state.likeReplyInProgress) {
            setState({ ...state, likeReplyInProgress: true })
            likeReplyComment(reply.id, like_value, authToken).then(() => {
                let commentsData = poll.comments.slice()
                let selectedComment = commentsData.find((item: any) => item.id === comment.id)
                let repliesData = selectedComment.replies.slice()
                let replyData = repliesData.find((item: any) => item.id === reply.id)
                replyData.num_likes = like_value === 1 ? Number(replyData.num_likes || 0) + 1 : Number(replyData.num_likes || 0) - 1
                replyData.num_dislikes = replyData.like_value === -1 ? Number(replyData.num_dislikes) - 1 : replyData.num_dislikes
                replyData.like_value = like_value
                setState({ ...state, likeInProgress: false, poll: { ...state.poll, comments: commentsData } })
            })
        }
    }

    const handleDislikeReplyComment = (comment: any, reply: any) => {
        const { poll } = state
        const like_value = comment.like_value === 1 ? -1 : comment.like_value === -1 ? 0 : -1

        if (!state.dislikeReplyInProgress) {
            setState({ ...state, dislikeReplyInProgress: true })
            likeReplyComment(comment.id, like_value, authToken).then(() => {
                let commentsData = poll.comments.slice()
                let selectedComment = commentsData.find((item: any) => item.id === comment.id)
                let repliesData = selectedComment.replies.slice()
                let replyData = repliesData.find((item: any) => item.id === reply.id)
                replyData.num_likes = replyData.like_value === 1 ? Number(replyData.num_likes) - 1 : replyData.num_likes
                replyData.num_dislikes = like_value === -1 ? Number(replyData.num_dislikes || 0) + 1 : Number(replyData.num_dislikes || 0) - 1
                replyData.like_value = like_value
                setState({ ...state, likeInProgress: false, poll: { ...state.poll, comments: commentsData } })
            })
        }
    }

    return (
        <>
            <Share
                open={shareOpen}
                setOpen={setShareOpen}
                text={`Share`}
                link={shareData.link}
                name={shareData.name}
            />
            <Info open={infoOpen} setOpen={setInfoOpen} text={infoText} setText={setInfoText} />
            <div>
                <Header currentPage='poll' title="OCOV's Poll" />
                {isLoading
                    ? <div className='container-center'><CircularProgress /></div>
                    : <>
                        <div className='poll-wrapper'>
                            <div className='poll-icons-wrapper'>
                                <Tooltip title='Back'>
                                    <IconButton
                                        size='large'
                                        edge='start'
                                        color='inherit'
                                        aria-label='menu'
                                        onClick={() => history.goBack()}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Tooltip>
                                <div>
                                    {state.isFollowing ?
                                        <Tooltip title='Following'>
                                            <IconButton
                                                size='large'
                                                edge='start'
                                                color='inherit'
                                                aria-label='menu'
                                                onClick={() => handleFollow()}
                                                style={{ marginBottom: 10 }}
                                            >
                                                <StarRateIcon />
                                            </IconButton>
                                        </Tooltip> :
                                        <Tooltip title='Follow'>
                                            <IconButton
                                                size='large'
                                                edge='start'
                                                color='inherit'
                                                aria-label='menu'
                                                onClick={() => handleFollow()}
                                                style={{ marginBottom: 10 }}
                                            >
                                                <StarBorderIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {state.poll && state.poll.more_info &&
										<Tooltip title='More Information'>
											<IconButton
												size='large'
												edge='start'
												color='inherit'
												aria-label='info'
												onClick={() => {
                                                    setInfoOpen(true);
                                                    setInfoText(state.poll.more_info.markdown)
                                                }}
												style={{ marginBottom: 10 }}
											>
												<InfoOutlinedIcon />
											</IconButton>
										</Tooltip>
                                    }
                                    <Tooltip title='Share'>
                                        <IconButton
                                            size='large'
                                            edge='start'
                                            color='inherit'
                                            aria-label='menu'
                                            onClick={() => {
                                                setShareOpen(true);
                                                setShareData({ link: state?.poll?.permalink, name: state?.poll?.name })
                                            }}
                                            style={{ marginBottom: 10 }}
                                        >
                                            <ShareIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <div
                                className='poll-image-wrapper'
                                style={{
                                    backgroundImage: `url(${state.poll.hero_image})`
                                }}
                            >
                                {state.poll.hero_image_copyright &&
									<p className='poll-copyrigth-text'>Image &copy; {state.poll.hero_image_copyright}</p>
                                }
                            </div>
                            {state.poll.name && <p className='poll-name-text'>{state.poll.name}</p>}
                            {state.poll.answers.map((answer: any, index: number) => (
                                <div key={index} className={`poll-answer-button-wrapper ${state.isVoted ? 'vote' : ''}`}>
                                    <div
                                        className={`poll-answer-button ${state.answerId === answer.id ? 'poll-answer-button-selected' : ''} 
                                    ${state.isVoted ? '' : 'vote'} `
                                        }
                                        key={answer.id}
                                        onClick={() => setAnswer(answer, state.isVoted)}
                                    >
                                        <p className='poll-answer-button-text'>{answer.name}</p>
                                        {state.answerId === answer.id && <div className='selected' />}
                                        {!!state.isVoted && !isEmpty(authToken) && <div className='background' style={{ width: getResults(answer, 'width') }} />}
                                    </div>
                                    {!!state.isVoted && state.poll && !isEmpty(authToken) && (
                                        <p className='poll-answer-percentage-text'>{getResults(answer, '')}</p>
                                    )}
                                </div>
                            ))}
                            {((!(!!state.isVoted) && !!state.poll) || isEmpty(authToken)) && (
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='error'
                                    className='poll-vote-button'
                                    onClick={handleVote}
                                    disabled={!(!!state.answerId) || isLoadingVote}
                                >
                                    {isLoadingVote ? <CircularProgress size={24} /> : 'Vote'}
                                </Button>
                            )}
                        </div>
                        {state.isVoted && state.poll && !isEmpty(authToken) && (
                            <div className='poll-comments-wrapper'>
                                <p className='poll-comment-title'>Comments</p>
                                <Comment
                                    isReply={false}
                                    title='Write Comment'
                                    commentName={state.poll.name}
                                    text={commentText}
                                    textChange={setCommentText}
                                    handlePostComment={handlePostComment}
                                    id={state.poll.id}
                                    authToken={authToken}
                                />
                                {state.poll.comments &&
									<CommentsBlock
										comments={state.poll.comments}
										handleLikeComment={handleLikeComment}
										handleDislikeComment={handleDislikeComment}
										commentName={state.poll.name}
										id={state.poll.id}
										authToken={authToken}
										handlePostReply={handlePostReply}
										handleLikeReplyComment={handleLikeReplyComment}
										handleDislikeReplyComment={handleDislikeReplyComment}
										setShareOpen={setShareOpen}
										setShareData={setShareData}
										replyText={replyText}
										setReplyText={setReplyText}
										commentId={commentId}
										setCommentId={setCommentId}
										replyId={replyId}
										setReplyId={setReplyId}
									/>
                                }
                            </div>
                        )}
                    </>
                }
                <Footer />
            </div>
        </>
    )
}