import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import {
    EmailShareButton, FacebookShareButton, HatenaShareButton, InstapaperShareButton,
    LineShareButton, LinkedinShareButton, LivejournalShareButton, FacebookMessengerShareButton,
    WeiboShareButton, PinterestShareButton, PocketShareButton, RedditShareButton,
    TelegramShareButton, TumblrShareButton, TwitterShareButton, ViberShareButton,
    WhatsappShareButton, WorkplaceShareButton, FacebookIcon, TwitterIcon, LinkedinIcon,
    PinterestIcon, TelegramIcon, WhatsappIcon, RedditIcon, TumblrIcon, EmailIcon,
    ViberIcon, WorkplaceIcon, LineIcon, PocketIcon, InstapaperIcon, WeiboIcon,
    HatenaIcon, LivejournalIcon
} from 'react-share'
import { useWindowDimensions } from '../../services/utils'
import './Share.css'

interface Props {
    open: boolean
    setOpen: any
    text: string
    link: string
    name: string
}

const shareButtons = [
    { button: FacebookShareButton, icon: FacebookIcon },
    { button: LinkedinShareButton, icon: LinkedinIcon },
    { button: TwitterShareButton, icon: TwitterIcon },
    { button: PinterestShareButton, icon: PinterestIcon },
    { button: TelegramShareButton, icon: TelegramIcon },
    { button: WhatsappShareButton, icon: WhatsappIcon },
    { button: RedditShareButton, icon: RedditIcon },
    { button: EmailShareButton, icon: EmailIcon },
    { button: TumblrShareButton, icon: TumblrIcon },
    { button: LivejournalShareButton, icon: LivejournalIcon },
    { button: ViberShareButton, icon: ViberIcon },
    { button: WorkplaceShareButton, icon: WorkplaceIcon },
    { button: LineShareButton, icon: LineIcon },
    { button: WeiboShareButton, icon: WeiboIcon },
    { button: PocketShareButton, icon: PocketIcon },
    { button: InstapaperShareButton, icon: InstapaperIcon },
    { button: HatenaShareButton, icon: HatenaIcon },
]

export default function Share(props: Props) {
    const { width } = useWindowDimensions()
    const [isCopied, setIsCopied] = useState(false)

    const handleClose = () => {
        props.setOpen(false)
    }

    async function copyTextToClipboard(text: string) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text)
        } else {
            return document.execCommand('copy', true, text)
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(props.link)
            .then(() => {
                setIsCopied(true)
                setTimeout(() => {
                    setIsCopied(false)
                }, 1500)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div>
            <Dialog
                sx={{
                    '.MuiPaper-root': {
                        margin: '0 10px',
                        width: width > 762 ? 600 : 300
                    }
                }}
                open={props.open}
                onClose={handleClose}
            >
                <div className='share-wrapper'>
                    <p className='share-title'>{props.text}</p>
                    <div className='share-icons-wrapper'>
                        {shareButtons.map((media: any, index: number) => (
                            <media.button
                                key={index}
                                url={props.link}
                                quote={props.name}
                                className='share-button-wrapper'
                            >
                                <media.icon size={40} round />
                            </media.button>
                        ))}
                    </div>
                    <div className='share-copy-wrapper'>
                        <TextField
                            placeholder='Country of Residence'
                            variant='standard'
                            value={props.link}
                            className='share-form-input'
                            disabled
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            color='error'
                            className='share-copy-button'
                            onClick={handleCopyClick}
                        >
                            Copy
                        </Button>
                    </div>
                    {isCopied && <p className='share-copy-message'>Copied!</p>}
                </div>
            </Dialog>
        </div>
    )
}