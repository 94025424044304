import { state } from 'venti'
import theme from '../config'
import { setItemAsyncStorage } from '../services/helper'

const { storageKeys } = theme

export const initialUserState = {
	isLoggedIn: false,
	token: null,
	userName: null,
	tokenExp: null,
}

//state.set(storageKeys.user, initialState)

export const userLoginAction = async (user: any, authToken: string) => {
	await setItemAsyncStorage(storageKeys.user, JSON.stringify(user))
	await setItemAsyncStorage(storageKeys.authToken, authToken)
	state.set(storageKeys.authToken, authToken)
	state.set(storageKeys.user, Object.assign(initialUserState, user, { isLoggedIn: true }))
}

export const userLogoutAction = async () => {
	await setItemAsyncStorage(storageKeys.user, '')
	await setItemAsyncStorage(storageKeys.authToken, '')
	await state.set(storageKeys.user, null)
	await state.set(storageKeys.authToken, null)
}
