import React, { useState, useEffect } from 'react'
import './Debate.css'
import Microphone from '../../assets/images/microphone.png'
import Podiums from '../../assets/images/podiums.png'
import { ReactComponent as GooglePlayBadge } from '../../assets/images/google-play-badge.svg'
import { ReactComponent as AppStoreBadge } from '../../assets/images/app-store-badge.svg'
import { Header } from '../../components/Header'
import DebateForm from '../../components/DebateForm/DebateForm'
import { Footer } from '../../components/Footer'
import { getTrendingPolls } from '../../services/client'
import { CircularProgress } from '@mui/material'

type Poll = {
  id: string
  hero_image: string
  name: string
}

export default function Debate() {
  const [loading, setLoading] = useState<boolean>(true)
  const [polls, setPolls] = useState<Poll[]>([])

  useEffect(() => {
    (async () => {
      const data = await getTrendingPolls()
      setLoading(false)
      setPolls(data)
    })()
  },  [])

  const scrollToForm = () => {
    window.scrollTo({ top: 1400, left: 0, behavior: 'smooth' })
  }
  
  return (
    <>
      <Header currentPage="debate" title="Debate" />
      <main className="debate">
        <section className="desktop">
          <div className="container">
            <div className="row">
              <div className="column">
                <h1>American citizens have a voice, and it's time to be heard.</h1>
                <div className="info">
                  <p>Debate the other party live.</p>
                  <p>Sign up to be a debater today.</p>
                </div>
                <a className="button" onClick={scrollToForm}>PROVE YOUR KNOWLEDGE</a>
              </div>
              <div className="column">
                <img id="battleImg" src={Microphone} alt="Podium microphone" />
              </div>
            </div>
          </div>
          <div id="cardsContainer" className="fullwidth bg-blue">
            <h3>How Do Our Debates Work?</h3>
            <div className="row">
              <div className="card">
                <div className="cardNumber bg-red">01</div>
                <h4>Use the sign-up form</h4>
                <p>Enter in your contact information, and sign the terms and conditions.</p>
              </div>
              <div className="card">
                <div className="cardNumber bg-red">02</div>
                <h4>Get contacted and send your schedule.</h4>
                <p>We reach out with the topics we're debating on so you can prepare, and ask for your schedule for the days
                  we've set aside to have the debates. </p>
              </div>
              <div className="card">
                <div className="cardNumber bg-red">03</div>
                <h4>Meet your debate opponent, and it's on.</h4>
                <p>You'll jump on a call with your opponent and a moderator at a set time. You'll talk through all the
                  topics and really be able to show your knowledge!<br /><br />
                  The video will be posted on the app, as well as on our YouTube channel.</p>
              </div>
            </div>
          </div>
          <div id="formContainer">
            <div className="row">
              <div className="column">
                <DebateForm />
              </div>
              <div className="column">
                <h2>Debate Sign-Up Form</h2>
                <p className="infoText">Don't hesitate, the time is yours</p>
                <img id="podiums" src={Podiums} alt="Debating podiums" />
              </div>
            </div>
          </div>
        
          <div id="appContainer">
            <div className="row">
              <div className="column">
                <div className="appPreviewBG">
                  {loading
                    ? <CircularProgress className="loading" />
                    : <>
                        <div className="previewContainer">
                          <div className="previewPoll">
                            <img className="previewPollHeroImage" src={polls[0]?.hero_image} alt={polls[0]?.name} />
                            <div className="previewPollName">{polls[0]?.name}</div>
                            <div className="buttonRow">
                              <div className="fakeLink">Share</div>
                              <div className="dot">•</div>
                              <div className="fakeLink">Follow</div>
                            </div>
                          </div>
                          {polls.map(poll => (
                            <div className="previewPollSmall">
                              <img className="previewPollSmallImage" src={poll.hero_image} alt={poll.name} />
                              <div className="previewPollSmallRightCol">
                                <div className="previewPollSmallName">{poll.name}</div>
                                <div className="buttonRowSmall">
                                  <div className="fakeLink">Share</div>
                                  <div className="dot">•</div>
                                  <div className="fakeLink">Follow</div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </> }
                </div>
              </div>
              <div className="column">
                <h2>Download Our Mobile App</h2>
                <p>Don't miss out on any of the nation's tough questions - voice your opinion any day, any time.</p>
                <div className="links">
                  <a href="https://play.google.com/store/apps/details?id=com.ocov.vote"><GooglePlayBadge /></a>
                  <a href="https://apps.apple.com/us/app/our-country-our-vote/id1490201042"><AppStoreBadge /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
