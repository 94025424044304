import { useEffect, useState } from 'react'
import { useVenti } from 'venti'
import { useQuery, useQueryClient } from 'react-query'
import { filter } from 'lodash'
import PublishSubscribe from 'publish-subscribe-js'
import { useHistory } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'
import Share from '../../components/Modals/Share'
import theme from '../../config'
import { getNextCategoryChunk, searchPolls } from '../../services/client'
import './Polls.css'

interface Props {
    id: number,
}

interface Poll {
    name: string,
    hero_image: string
}

export default function Polls(props: Props) {
    const history = useHistory()
    const queryClient = useQueryClient()
    const { id } = props
    const ventiState = useVenti()
    const authToken = ventiState.get(theme.storageKeys.authToken, {})
    const { data: polls = [], isLoading } = useQuery<Poll[]>(`polls-${id}`, () => getNextCategoryChunk(id))
    const [shareOpen, setShareOpen] = useState<boolean>(false)
    const [shareInfo, setShareInfo] = useState({
        name: '',
        link: ''
    })

    useEffect(() => {
        if (!shareOpen) {
            setShareInfo({ name: '', link: '' })
        }
    }, [shareOpen])

    useEffect(() => {
        PublishSubscribe.subscribe('SEARCH_POLL', (data: any) => {
            searchPolls(data.search, authToken)
                .then((responce) => {
                    if (!!responce) {
                        if (data.category.name === 'Trending' || data.category.name === 'Latest') {
                            queryClient.setQueryData(`polls-${id}`, responce)
                        } else {
                            const filtered = filter(responce, { category_id: id })
                            queryClient.setQueryData(`polls-${id}`, filtered)
                        }
                    }
                })
                .catch((err) => console.log('Error: ', err))
        })
        return () => {
            PublishSubscribe.unsubscribe('SEARCH_POLL')
        }
    }, [])

    useEffect(() => {
        PublishSubscribe.subscribe('RESET_SEARCH_POLL', () => {
            getNextCategoryChunk(id)
                .then((responce) => {
                    if (!!responce) {
                        queryClient.setQueryData(`polls-${id}`, responce)
                    }
                })
                .catch((err) => console.log('Error: ', err))

        })
        return () => {
            PublishSubscribe.unsubscribe('RESET_SEARCH_POLL')
        }
    }, [])

    const handleShare = (item: any) => {
        setShareInfo({ name: item.name, link: item.permalink })
        setShareOpen(true)
    }

    const goToPoll = (item: any) => {
        history.push(`/polls/${item.slug}`)
    }

    return (
        <>
            <Share
                open={shareOpen}
                setOpen={setShareOpen}
                text={`Share`}
                link={shareInfo?.link}
                name={shareInfo?.name}
            />
            <div className='polls-wrapper'>
                {isLoading
                    ? <div className='container-center'><CircularProgress /></div>
                    : polls.length === 0 ?
                        <div className='polls-no-results-wrapper'>
                            <SearchIcon sx={{ fontSize: 80, color: '#cfcfcf' }} />
                            <p className='polls-no-search-main-text'>NO SEARCH RESULTS FOUND</p>
                            <p className='polls-no-search-sub-text'>We were unable find any exact matches for your search.</p>
                        </div>
                        : polls.map((item: any, index: number) => (
                            index === 0 ?
                                <div key={index} className='polls-main-poll-wrapper'>
                                    <div onClick={() => goToPoll(item)} className='polls-cursor'>
                                        <img className='polls-main-image' src={item.hero_image} alt='Poll Image' />
                                    </div>
                                    <p className='polls-description polls-cursor' onClick={() => goToPoll(item)}>{item.name}</p>
                                    <div className='polls-links-wrapper'>
                                        <button
                                            type='button'
                                            className='polls-link-button'
                                            onClick={() => handleShare(item)}
                                        >
                                            Share
                                        </button>
                                    </div>
                                </div> :
                                <div key={index} className='polls-poll-wrapper'>
                                    <Divider sx={{ marginBottom: 2.5 }} />
                                    <div className='polls-small-poll-wrapper' >
                                        <img onClick={() => goToPoll(item)} className='polls-small-image polls-cursor' src={item.hero_image} alt='Poll Image' />
                                        <div className='polls-small-description-wrapper'>
                                            <p onClick={() => goToPoll(item)} className='polls-description polls-cursor'>{item.name}</p>
                                            <div className='polls-links-wrapper'>
                                                <button
                                                    type='button'
                                                    className='polls-link-button'
                                                    onClick={() => handleShare(item)}
                                                >
                                                    Share
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        ))
                }
            </div>
        </>
    )
}