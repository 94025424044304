import React from 'react'
import IconButton from '@mui/material/IconButton'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import Comment from '../Comment'
import { useWindowSize } from '../../hooks/useWindowSize'
import { getCreatedDate } from '../../services/helper'
import './CommentsBlock.css'

interface Props {
    comments: any,
    handleLikeComment: (comment: any) => void,
    handleDislikeComment: (comment: any) => void,
    commentName: string,
    id: number | null,
    authToken: string,
    handlePostReply: (commentId: number, comment: any, replyId: null | number, resetId: any, resetMesssageText: any) => void,
    handleLikeReplyComment: (comment: any, reply: any) => void,
    handleDislikeReplyComment: (comment: any, reply: any) => void,
    setShareOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setShareData: React.Dispatch<React.SetStateAction<{
        link: string;
        name: string;
    }>>,
    setReplyText: React.Dispatch<React.SetStateAction<string>>,
    replyText: string,
    commentId: number | null,
    setCommentId: React.Dispatch<React.SetStateAction<number | null>>,
    replyId: number | null,
    setReplyId: React.Dispatch<React.SetStateAction<number | null>>
}

export default function CommentsBlock(props: Props) {
    const {
        comments, handleLikeComment, handleDislikeComment, commentName, id,
        authToken, handlePostReply, handleLikeReplyComment, handleDislikeReplyComment,
        setShareOpen, setShareData, setReplyText, replyText, commentId,
        setCommentId, replyId, setReplyId
    } = props
    const [width] = useWindowSize()

    return (
        comments.map((comment: any, index: number) => (
            <div key={index} className='block-comment-wraper'>
                {comment?.user?.avatar ?
                    <img
                        className='block-avatar'
                        src={comment?.user?.avatar}
                        alt='Avatar' /> :
                    <div className='block-avatar-wrapper'>
                        {comment?.user?.username ?
                            <p className='block-avatar-name'>{comment?.user?.username.substring(0, 2).toUpperCase()}</p> :
                            <p className='block-avatar-name'>{comment?.email.substring(0, 2).toUpperCase()}</p>
                        }
                    </div>
                }
                <div className='block-comment-body'>
                    <div key={index} className='block-commment-header'>
                        <p className='block-username'>{comment?.user?.username}</p>
                        <p className='block-date'>{getCreatedDate(comment.insert_time, 'comment')}</p>
                    </div>
                    <p className='block-comment-text'>{comment.message}</p>
                    <div className='block-comment-footer'>
                        <div className='block-social-wrapper'>
                            <button
                                type='button'
                                className='block-link-button'
                                onClick={() => { setCommentId(comment.id); setReplyId(null); setReplyText('') }}
                            >
                                Reply
                            </button>
                            <p className='block-dot'>&#9679;</p>
                            <button
                                type='button'
                                className='block-link-button'
                                onClick={() => {
                                    setShareOpen(true);
                                    setShareData({ link: comment?.permalink, name: comment?.message })
                                }}
                            >
                                Share
                            </button>
                        </div>
                        {width > 768 && <p className='block-dot'>&#9679;</p>}
                        <div className='block-social-wrapper'>
                            <p className='block-counter'>{comment.num_likes > 0 ? `+${comment.num_likes}` : comment.num_likes}</p>
                            <Tooltip title='Like'>
                                <IconButton
                                    size='small'
                                    edge='start'
                                    color='inherit'
                                    aria-label='menu'
                                    onClick={() => handleLikeComment(comment)}
                                    sx={{ '.MuiSvgIcon-root': { fontSize: 20 } }}
                                >
                                    <ThumbUpOffAltIcon style={{ color: comment.like_value > 0 ? '#BF0831' : '#828282' }} />
                                </IconButton>
                            </Tooltip>
                            <p className='block-dot'>&#9679;</p>
                            <p className='block-counter'>{comment.num_dislikes > 0 ? `-${comment.num_dislikes}` : comment.num_dislikes}</p>
                            <Tooltip title='Dislike'>
                                <IconButton
                                    size='small'
                                    edge='start'
                                    color='inherit'
                                    aria-label='menu'
                                    onClick={() => handleDislikeComment(comment)}
                                    sx={{ '.MuiSvgIcon-root': { fontSize: 20 } }}
                                >
                                    <ThumbDownOffAltIcon style={{ color: comment.like_value < 0 ? '#BF0831' : '#828282' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    {commentId === comment.id &&
						<Comment
							isReply={true}
							title='Reply to comment'
							placeholder={`Reply to ${comment?.user?.username}...`}
							commentName={commentName}
							reply={comment}
							text={replyText}
							textChange={setReplyText}
							handlePostComment={
                                () => handlePostReply(comment.id, comment, null, setCommentId, setReplyText)
                            }
							id={id}
							authToken={authToken}
							commentId={commentId}
							resetId={setCommentId}
						/>
                    }
                    {comment.replies && comment.replies.map((reply: any, index: number) => (
                        <div key={index} className='block-replys-wrapper'>
                            <div className='block-reply-header'>
                                {reply?.user?.avatar ?
                                    <img
                                        className='block-avatar'
                                        src={reply?.user?.avatar}
                                        alt='Avatar' /> :
                                    <div className='block-avatar-wrapper'>
                                        {reply?.user?.username ?
                                            <p className='block-avatar-name'>{reply?.user?.username.substring(0, 2).toUpperCase()}</p> :
                                            <p className='block-avatar-name'>{reply?.user?.email.substring(0, 2).toUpperCase()}</p>
                                        }
                                    </div>
                                }
                                <p className='block-username'>{reply?.user?.username}</p>
                                <p className='block-date'>{getCreatedDate(reply.insert_time, 'comment')}</p>
                            </div>
                            <p className='block-reply-text'>
                                {comment?.user?.username
                                    ? <span className='block-reply-username'>@{comment?.user?.username}</span>
                                    : ""
                                } {reply.message}
                            </p>
                            <div className='block-reply-footer'>
                                <div className='block-social-wrapper'>
                                    <button
                                        type='button'
                                        className='block-link-button'
                                        onClick={() => { setCommentId(null); setReplyId(reply.id); setReplyText('') }}
                                    >
                                        Reply
                                    </button>
                                    <p className='block-dot'>&#9679;</p>
                                    <button
                                        type='button'
                                        className='block-link-button'
                                        onClick={() => {
                                            setShareOpen(true);
                                            setShareData({ link: comment?.permalink, name: comment?.message })
                                        }}
                                    >
                                        Share
                                    </button>
                                </div>
                                {width > 768 && <p className='block-dot'>&#9679;</p>}
                                <div className='block-social-wrapper'>
                                    <p className='block-counter'>{reply.num_likes > 0 ? `+${reply.num_likes}` : `${reply.num_likes}`}</p>
                                    <IconButton
                                        size='small'
                                        edge='start'
                                        color='inherit'
                                        aria-label='menu'
                                        onClick={() => handleLikeReplyComment(comment, reply)}
                                        sx={{ '.MuiSvgIcon-root': { fontSize: 20 } }}
                                    >
                                        <ThumbUpOffAltIcon style={{ color: reply.like_value > 0 ? '#BF0831' : '#828282' }} />
                                    </IconButton>
                                    <p className='block-dot'>&#9679;</p>
                                    <p className='block-counter'>{reply.num_dislikes > 0 ? `-${reply.num_dislikes}` : reply.num_dislikes}</p>
                                    <IconButton
                                        size='small'
                                        edge='start'
                                        color='inherit'
                                        aria-label='menu'
                                        onClick={() => handleDislikeReplyComment(comment, reply)}
                                        sx={{ '.MuiSvgIcon-root': { fontSize: 20 } }}
                                    >
                                        <ThumbDownOffAltIcon style={{ color: reply.like_value < 0 ? '#BF0831' : '#828282' }} />
                                    </IconButton>
                                </div>
                            </div>
                            {replyId === reply.id &&
								<Comment
									isReply={true}
									title='Reply to comment'
									placeholder={`Reply to ${reply?.user?.username}...`}
									commentName={commentName}
									reply={reply}
									text={replyText}
									textChange={setReplyText}
									handlePostComment={
                                        () => handlePostReply(reply.comment_id, reply, reply.id, setReplyId, setReplyText)
                                    }
									id={id}
									authToken={authToken}
									replyId={replyId}
									resetId={setReplyId}
								/>
                            }
                        </div>
                    ))}

                    <Divider sx={{ width: width <= 768 ? 300 : 700, marginBottom: 4, marginLeft: -6.25 }} />
                </div>
            </div>
        ))
    )
}