import { useReducer, useState } from 'react'
import { FormControl, InputLabel, Input } from '@material-ui/core'
import Button from '@mui/material/Button'
import { isEmailValid } from '../../services/helper'
import Logo from '../../images/ocov-version-2-750.png'
import { forgotPassword } from '../../services/client'
import './ForgotPassword.css'

interface Props {
	setForm: any,
	handleClose: any
}

function ForgotPassword(props: Props) {
	const [message, setMessage] = useState({ isError: false, messageText: '', isSuccess: false })
	const [formInput, setFormInput] = useReducer(
		(state: any, newState: any) => ({ ...state, ...newState }),
		{
			email: '',
		}
	)

	const enabledSignInButton = isEmailValid(formInput.email)

	const handleSubmit = (evt: any) => {
		evt.preventDefault()

		forgotPassword(formInput.email)
			.then(() => {
				setMessage({
					isError: false,
					messageText: `Please check your email for instructions to reset your password.`,
					isSuccess: true
				})
				setFormInput({ email: "" })
				return Promise.resolve()
			})
			.catch((error) => {
				setMessage({
					isError: true,
					messageText: `${formInput.email} was not found in our system`,
					isSuccess: false
				})
			})
	}

	const handleInput = (evt: any) => {
		if (message.isError) setMessage({ isError: false, messageText: '', isSuccess: false })
		const name = evt.target.id
		const newValue = evt.target.value
		setFormInput({ [name]: newValue })
	}

	return (
		<div className="auth-wrapper">
			<div className="auth-content-wrapper">
				<img className="auth-logo" src={Logo} alt="logo" />
				<h1 className="auth-title">Request Password Reset</h1>
				<form onSubmit={handleSubmit} className="auth-form-wrapper">
					<FormControl className="auth-form-input">
						<InputLabel htmlFor="email">Enter your email address</InputLabel>
						<Input
							id="email"
							defaultValue={formInput.email}
							onChange={handleInput}
						/>
					</FormControl>
					<Button
						type="submit"
						variant="contained"
						color="error"
						className="auth-form-button"
						disabled={!enabledSignInButton}
					>
						Submit
					</Button>
				</form>

				{message && <p className={`message ${message.isError ? 'error' : 'success'}`}>{message.messageText}</p>}

				<div className="auth-link-wrapper">
					<p>{ !message.isSuccess ? 'I think I remember.' : 'Return to' }
						<Button onClick={() => props.setForm("login")}>
							Sign In
						</Button>
					</p>
				</div>
			</div>
		</div>
	)
}

export default ForgotPassword