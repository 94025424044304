import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useWindowDimensions } from '../../services/utils'
import './SelectValue.css'

interface Props {
    open: boolean
    setOpen: any
    text: string
    list: any
    confirm: any
    field: any
    fieldValue: string
    setFieldValue: any
    setHelpText: any
    setFieldName: any
}

export default function YesNo(props: Props) {
    const { width } = useWindowDimensions()
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        setInputValue(props.fieldValue)
    }, [props])

    const handleChange = (event: SelectChangeEvent) => {
        setInputValue(event.target.value)
    }

    const handleClose = () => {
        props.setOpen(false)
        props.setFieldValue('')
        props.setHelpText('')
        props.setFieldName('')
        setInputValue('')
    }

    const handleSave = () => {
        props.confirm({ [props.field]: inputValue })
        handleClose()
    }

    return (
        <div>
            <Dialog
                sx={{
                    '.MuiPaper-root': {
                        margin: '0 10px',
                        width: width > 762 ? 380 : 300
                    }
                }}
                open={props.open}
                onClose={handleClose}
            >
                <div className='select-value-wrapper'>
                    <p className='select-value-title'>{props.text}</p>
                    <FormControl
                        variant='standard'
                        className='select-value-select-wrapper'
                    >
                        <Select
                            id='select'
                            value={inputValue}
                            onChange={handleChange}
                        >
                            {props.list.map((item: any, index: number) => (
                                <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button
                        type='submit'
                        variant='contained'
                        color='error'
                        className='select-value-button'
                        onClick={handleSave}
                    >
                        Select
                    </Button>
                </div>
            </Dialog>
        </div>
    )
}