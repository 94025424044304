import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button } from "@mui/material"

type Props = {
    title: string,
    message: string,
    open: boolean,
    handleClose: any,
}

export default function Alert({ title, message, open, handleClose }: Props) {
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}