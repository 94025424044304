import { useState } from 'react'
import moment from "moment/moment"
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import SelectValue from '../Modals/SelectValue'
import { useWindowSize } from '../../hooks/useWindowSize'
import countries from '../../services/countries'
import states from '../../services/states'
import { find } from "lodash"
import { socialIssues, politicalParty, educationData, genderData, fiscalData,
    economicIdeology, incomeData, raceData, religionData, sexualData,
    USCitizen, registeredToVote, militaryService, socialIssuesHelpText,
    economicIdeologyHelpText } from '../../services/constants'
import './Demographics.css'

interface Props {
    demographicsState: any
    setDemographicsState: any
}

export default function Demographics(props: Props) {
    const { demographicsState, setDemographicsState } = props
    const [width] = useWindowSize()

    const convertUTCDateToLocalDate = (date: any) => {
        return moment(new Date(date)).format('MM/DD/YYYY')
    }

    const [selectValueOpen, setSelectValueOpen] = useState<boolean>(false)
    const [helpText, setHelpText] = useState<string>('')
    const [list, setList] = useState<any>([])
    const [fieldName, setFieldName] = useState<string>('')
    const [fieldValue, setFieldValue] = useState<string>('')

    const handleSelectField = (name: string) => {
        switch (name) {
            case 'country':
                setList(countries)
                setHelpText("Country of Residence")
                break
            case 'state':
                setList(states)
                setHelpText("State")
                break
            case 'party':
                setList(politicalParty)
                setHelpText("Political Party")
                break
            case 'education':
                setList(educationData)
                setHelpText("Education")
                break
            case 'income':
                setList(incomeData)
                setHelpText("Income")
                break
            case 'gender':
                setList(genderData)
                setHelpText("Gender Identity")
                break
            case 'race':
                setList(raceData)
                setHelpText("Ethnicity")
                break
            case 'religion':
                setList(religionData)
                setHelpText("Religion")
                break
            case 'sexual_orientation':
                setList(sexualData)
                setHelpText("Sexual Identity")
                break
            case 'us_citizen':
                setList(USCitizen)
                setHelpText("U.S Citizen?")
                break
            case 'registered_to_vote':
                setList(registeredToVote)
                setHelpText("Register to Vote?")
                break
            case 'military_service':
                setList(militaryService)
                setHelpText("Served in the U.S Armed Forces?")
                break
            case 'fiscal_views':
                setList(fiscalData)
                setHelpText("Fiscal Identity")
                break
            case 'social_views':
                setList(socialIssues)
                setHelpText(socialIssuesHelpText)
                break
            case 'economic_ideology':
                setList(economicIdeology)
                setHelpText(economicIdeologyHelpText)
                break
            default:
                return null
        }
        setSelectValueOpen(true)
        setFieldName(name)
        setFieldValue(demographicsState[name])
    }

    return (
        <>
            <SelectValue
                open={selectValueOpen}
                setOpen={setSelectValueOpen}
                list={list}
                text={helpText}
                field={fieldName}
                fieldValue={fieldValue}
                confirm={setDemographicsState}
                setFieldValue={setFieldValue}
                setHelpText={setHelpText}
                setFieldName={setFieldName}
            />
            <div className='demographics-wrapper'>
                <div className='demographics-block-wrapper'>
                    <p className='demographics-title'>MY INFORMATION</p>
                    <div className='demographics-save-form-wrapper'>
                        <div className='demographics-save-row'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label='Birthdate'
                                    value={demographicsState.birthdate}
                                    className='demographics-form-input'
                                    inputFormat='MM/DD/YYYY'
                                    onChange={(newValue) => setDemographicsState({ 'birthdate': convertUTCDateToLocalDate(newValue) })}
                                    renderInput={(params) => <TextField variant='standard' {...params} />}
                                />
                            </LocalizationProvider>
                            <TextField
                                id='zipcode'
                                value={demographicsState.zipcode || ''}
                                className='demographics-form-input'
                                label='Postal Code'
                                onChange={(e) => setDemographicsState({ 'zipcode': e.target.value.replace(/[^0-9.]/g, '') })}
                                inputProps={{ maxLength: 5 }}
                                variant='standard'
                            />
                        </div>
                    </div>
                    <div className='demographics-form-wrapper'>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('country')}
                            >
                                Country of Residence
                            </Button>
                            <TextField
                                placeholder='Country of Residence'
                                variant='standard'
                                value={demographicsState.country ? find(countries, { value: demographicsState.country })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.country ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('state')}
                            >
                                State
                            </Button>
                            <TextField
                                placeholder='State'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.state ? find(states, { value: demographicsState.state })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.state ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('party')}
                            >
                                Political Party
                            </Button>
                            <TextField
                                placeholder='Political Party'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.party ? find(politicalParty, { value: demographicsState.party })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.party ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('education')}
                            >
                                Education
                            </Button>
                            <TextField
                                placeholder='Education'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.education ? find(educationData, { value: demographicsState.education })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.education ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('income')}
                            >
                                Income
                            </Button>
                            <TextField
                                placeholder='Income'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.income ? find(incomeData, { value: demographicsState.income })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.income ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('gender')}
                            >
                                Gender Identity
                            </Button>
                            <TextField
                                placeholder='Gender Identity'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.gender ? find(genderData, { value: demographicsState.gender })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.gender ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('economic_ideology')}
                            >
                                Economic Ideology
                            </Button>
                            <TextField
                                placeholder='Economic Ideology'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.economic_ideology ? find(economicIdeology, { value: demographicsState.economic_ideology })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.economic_ideology ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('race')}
                            >
                                Ethnicity
                            </Button>
                            <TextField
                                placeholder='Ethnicity'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.race ? find(raceData, { value: demographicsState.race })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.race ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('religion')}
                            >
                                Religion
                            </Button>
                            <TextField
                                placeholder='Religion'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.religion ? find(religionData, { value: demographicsState.religion })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.religion ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('sexual_orientation')}
                            >
                                Sexual Identity
                            </Button>
                            <TextField
                                placeholder='Sexual Identity'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.sexual_orientation ? find(sexualData, { value: demographicsState.sexual_orientation })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.sexual_orientation ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('us_citizen')}
                            >
                                U.S Citizen?
                            </Button>
                            <TextField
                                placeholder='U.S. Citizen?'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.us_citizen ? find(USCitizen, { value: demographicsState.us_citizen })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.us_citizen ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('registered_to_vote')}
                            >
                                Registered to vote?
                            </Button>
                            <TextField
                                placeholder='Registered to vote?'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.registered_to_vote ? find(registeredToVote, { value: demographicsState.registered_to_vote })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.registered_to_vote ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('military_service')}
                            >
                                Served in the U.S Armed Forces?
                            </Button>
                            <TextField
                                placeholder='Served in the U.S Armed Forces?'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.military_service ? find(militaryService, { value: demographicsState.military_service })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.military_service ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                    </div>

                </div>
                <div className='demographics-block-wrapper'>
                    <p className='demographics-title'>POLITICAL IDEOLOGY</p>
                    <div className='demographics-form-wrapper'>

                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('social_views')}
                            >
                                Social Issues
                            </Button>
                            <TextField
                                placeholder='Social Issues'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.social_views ? find(socialIssues, { value: demographicsState.social_views })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.social_views ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                        <>
                            <Button
                                variant='outlined'
                                className='demographics-form-button'
                                onClick={() => handleSelectField('fiscal_views')}
                            >
                                Fiscal Identity
                            </Button>
                            <TextField
                                placeholder='Fiscal Identity'
                                id='standard-basic'
                                variant='standard'
                                value={demographicsState.fiscal_views ? find(fiscalData, { value: demographicsState.fiscal_views })?.name : ''}
                                className='demographics-form-input'
                                disabled
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        "-webkit-text-fill-color": demographicsState.fiscal_views ? "#000" : "#00000061"
                                    },
                                }}
                            />
                        </>
                        {width <= 762 && <Divider sx={{ width: 300, marginTop: 2, borderWidth: 1 }} />}
                    </div>
                </div>
            </div>
        </>
    )
}