import React, { useEffect, useState } from 'react'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import './Articles.css'
import { getArticles } from "../../services/client"
import { Article } from "./Article"
import { stripTags } from "../../services/utils"
import CircularProgress from "@mui/material/CircularProgress"

export default function Articles() {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [articles, setArticles] = useState<Article[]>([])

    useEffect(() => {
        getArticles()
            .then(data => setArticles(data))
            .finally(() => setIsLoading(false))
    }, [])

    return (
        <div>
            <Header currentPage={'archive'} title="Blog Archive" />
            <div className="wrapper">
                <div className="container">
                    { isLoading
                        ? <div className="container-center"><CircularProgress /></div>
                        : articles.map((article:Article) => (
                           <article key={article.slug} className="archive-article">
                               <div className="left-col">
                                   <title><a href={`/archive/${article.slug}`}>{article.title}</a></title>
                                   <p>{stripTags(article.body).split(' ').slice(0, 80).join(' ')}</p>
                                   <a className="button" href={`/archive/${article.slug}`}>Read Full Article</a>
                               </div>
                               <div className="right-col">
                                   <a href={`/archive/${article.slug}`}><img src={article.hero_image} /></a>
                               </div>
                           </article>
                        ))
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}
