import React from 'react'
import './Footer.css'

export const Footer = () => {

    return (
        <>
            <footer className="fullwidth bg-blue">
                <section className="links">
                    <ul>
                        <li><a href="/contact">Contact Us</a></li>
                        |
                        <li><a href="https://play.google.com/store/apps/details?id=com.ocov.vote" target="_blank" rel="noopener">Android
                            App</a></li> |
                        <li><a href="https://apps.apple.com/us/app/our-country-our-vote/id1490201042" target="_blank" rel="noopener">iOS
                            App</a></li> |
                        <li><a href="https://www.facebook.com/ocov.us/" target="_blank" rel="noopener">Facebook</a></li> |
                        <li><a href="https://www.instagram.com/ocov.us/" target="_blank" rel="noopener">Instagram</a></li> |
                        <li><a href="https://twitter.com/OCOV_US" target="_blank" rel="noopener">Twitter</a></li> |
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </section>
            </footer>
        </>
    )
}
