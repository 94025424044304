import React from 'react'
import './ReuqestAccountDeletion.css'
import { Header } from '../../components/Header'
import { ReactComponent as ContactIllustration } from '../../assets/images/contactus.svg'
import { Footer } from '../../components/Footer'
import AccountDeletionForm from '../../components/AccountDeletionForm/AccountDeletionForm'

export default function RequestAccountDeletion() {
  return (
    <>
      <Header currentPage="deleteAccount" title="Request Account Deletion" />
      <section className="deleteAccount">
        <div className="container">
          <div className="row">
            <div className="column">
              <AccountDeletionForm />
            </div>
            <div className="column">
              <p className="info">We're sorry to see you go!</p>
              <p className="info2">If you wish to have your account and all data with your account permanently deleted from our system, please fill out the form below.</p>
              <ContactIllustration className="infoIllustration" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
