import React from 'react'
import './Contact.css'
import { Header } from '../../components/Header'
import { ReactComponent as ContactIllustration } from '../../assets/images/contactus.svg'
import { Footer } from '../../components/Footer'
import ContactForm from '../../components/ContactForm/ContactForm'

export default function Contact() {
  
  return (
    <>
    <Header currentPage="contact" title="Contact Us" />
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="column">
              <ContactForm />
            </div>
            <div className="column">
              <p className="info">We'd love to hear from you!</p>
              <p className="info2">Have a topic you want to see as a poll? Or just looking to ask a question? Send us a message and we'll get right back to you.</p>
              <ContactIllustration className="infoIllustration" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
