import { useState, useReducer } from 'react'
import { FormControl, InputLabel, Input, FormHelperText,
	CircularProgress } from '@material-ui/core'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { isEmailValid } from '../../services/helper'
import Logo from '../../images/ocov-version-2-750.png'
import { authenticate } from '../../services/client'
import { userLoginAction } from '../../store/user'
import theme from '../../config'
import './SignIn.css'

interface Props {
	setForm: any,
	handleClose: any
}

function SignIn(props: Props) {
	const [formInput, setFormInput] = useReducer(
		(state: any, newState: any) => ({ ...state, ...newState }),
		{
			email: '',
			password: '',
		}
	)
	const [emailValidation, setEmailValidation] = useState<boolean>(true)
	const [passwordValidation, setPasswordValidation] = useState<boolean>(true)
	const [loading, setLoading] = useState<boolean>(false)
	const [message, setMessage] = useState({ isError: false, messageText: '' })

	const enabledSignInButton =
		isEmailValid(formInput.email) &&
		formInput.password.length >= theme.validation.passwordLength

	const emailOnBlur = () => setEmailValidation(isEmailValid(formInput.email))
	const passwordOnBlur = () =>
		setPasswordValidation(
			formInput.password.length >= theme.validation.passwordLength
		)

	const handleSubmit = (evt: any) => {
		evt.preventDefault()
		setLoading(true)
		authenticate(String(formInput.email).toLowerCase(), formInput.password)
			.then(async login => {
				if (!login) {
					setMessage({
						isError: true,
						messageText: 'Please try again'
					})
				} else {
					const authToken = login.token
					const user = Object.assign({}, login.user)
					await userLoginAction(user, authToken)
					setLoading(false)
					props.handleClose()
				}
			})
			.catch((err) => {
				setMessage({
					isError: true,
					messageText: 'Please try again'
				})
				setLoading(false)
			})
	};

	const handleInput = (evt: any) => {
		if (message.isError) setMessage({ isError: false, messageText: '' })
		const name = evt.target.id
		const newValue = evt.target.value
		setFormInput({ [name]: newValue })
	};

	return (
		<div className="auth-wrapper">
			<div className="auth-content-wrapper">
				<img className="auth-logo" src={Logo} alt="logo" />
				<h1 className="auth-title">Sign in to your account</h1>
				<form onSubmit={handleSubmit} className="auth-form-wrapper">
					<FormControl className="auth-form-input">
						<InputLabel htmlFor="email">Email</InputLabel>
						<Input
							id="email"
							onBlur={emailOnBlur}
							defaultValue={formInput.email}
							aria-describedby="my-helper-text"
							onChange={handleInput}
						/>
						{!emailValidation && (
							<FormHelperText className="error-text">
								Email is not valid
							</FormHelperText>
						)}
					</FormControl>
					<FormControl className="auth-form-input">
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input
							id="password"
							onBlur={passwordOnBlur}
							type="password"
							defaultValue={formInput.password}
							onChange={handleInput}
						/>
						{!passwordValidation && (
							<FormHelperText className="error-text">
								Password should be more than 8 symbols
							</FormHelperText>
						)}
					</FormControl>
					<Button
						type="submit"
						variant="contained"
						color="error"
						className="auth-form-button"
						disabled={!enabledSignInButton || loading}
					>
						{loading ? <CircularProgress size={24} /> : 'Login'}
					</Button>
				</form>
				{message && <p className={`message ${message.isError ? 'error' : 'success'}`}>{message.messageText}</p>}
				<div className="auth-link-wrapper">
					<Button onClick={() => props.setForm("forgot-password")}>
						Forgot Password
					</Button>
				</div>
				<Divider sx={{ width: "100%" }}>or</Divider>
				<div className="auth-link-wrapper">
					<Button onClick={() => props.setForm("create-account")}>
						Create an account
					</Button>
				</div>
			</div>
		</div>
	)
}

export default SignIn