import { Icon, Divider } from '@mui/material'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import DrapedFlag from '../../assets/images/draped-flag.jpeg'
import WeHearYou from '../../assets/images/we-hear-you.png'
import PublishSubscribe from 'publish-subscribe-js'
import './Home.css'

export default function About() {
  const goToLogin = () => {
    PublishSubscribe.publish('SHOW_LOGIN')
  }

  return (
    <>
      <Header currentPage="home" title="" />
      <section className="home">
        <div className="fullwidth bg-blue">
          <div className="container">
            <div className="home-about-wrapper">
              <div className="home-title-wrapper">
                <h1 className="home-title">Our Country. Our Vote.</h1>
                <p className="home-description">
                  Where you can vote and debate on issues that matter to the
                  United States of America.
                </p>
              </div>
              <img className="home-top-logo" src={DrapedFlag} alt="Woman with American Flag draped around shoulders" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="home-who-block-wrapper">
            <div className="home-who-img-wrapper">
              <img className="home-who-img" id="battleImg" src={WeHearYou} alt="We hear you" />
            </div>
            <div className="home-who-content-wrapper">
              <h1 className="home-who-title">Who is OCOV?</h1>
              <div className="info">
                <p className="home-who-subtitle">The majority of Americans find talking about politics stressful. We’re here to change that.</p>
                <p className="home-who-subtitle">OCOV is a social community for passionate Americans who want to give power to
                  their voice. Here, you can cast your vote on current political issues, share your
                  viewpoints in a live, online debate, and keep up-to-date on events through the blog.</p>
              </div>
              <div className="container home-blocks-wrapper">
                <div className="home-block-wrapper">
                  <Icon className="svg-icons">
                    <CheckCircleOutlinedIcon />
                  </Icon>
                  <p className="home-who-block-title">Real Topics</p>
                  <p className="home-who-block-description">
                    Vote on a range of relevant topics, from the economy to the environment, and help drive change in your local communities.
                  </p>
                </div>
                <div className="home-block-wrapper">
                  <Icon className="svg-icons">
                    <PermIdentityOutlinedIcon />
                  </Icon>
                  <p className="home-who-block-title">Real People</p>
                  <p className="home-who-block-description">
                    Go head to head with real Americans in a live, online debate. No filters. No fact-checkers. Just freedom.
                  </p>
                </div>
              </div>
              <div className="home-create">
                <a
                    className="button bg-blue"
                    onClick={goToLogin}
                    title="Create a Profile">
                    CREATE A PROFILE
                </a>
              </div>
              <p className="home-who-block-description">
                Place your vote where it matters most. Sign up for free and start voting TODAY!
              </p>
            </div>
          </div>
        </div>
        <div className="fullwidth bg-red">
          <div className="container">
            <div className="home-statistic-blocks-wrapper">
              <div className="home-statistic-wrapper">
                <p className="home-statistic-title">70%</p>
                <p className="home-statistic-description">
                  Facebook users reported getting into an argument in the comments section
                </p>
              </div>
              <div className="home-statistic-wrapper">
                <p className="home-statistic-title">5 HOURS</p>
                <p className="home-statistic-description">
                  Average time a person spends on Facebook per WEEK
                </p>
              </div>
              <div className="home-statistic-wrapper">
                <p className="home-statistic-title">0</p>
                <p className="home-statistic-description">
                  Time you have to waste on debates that don’t matter.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center pt-20 pb-20">
          <p>We’re tired of only hearing what politicians have to say. It’s time to hand over the podium to the American people.</p>
          <p>OCOV makes talking politics simple and productive. Our debate process encourages open, unfiltered discussions with the opposing party about relevant political topics you care about. We match real, informed Americans with one another to spark new ideas, share opinions and build a stronger community together. Each live, online debate is lightly moderated to keep the conversations on track and interruptions brief.</p>
          <p>So you too, can stand up for what you believe in, virtual Dr. Martin Luther King Jr. style.</p>
          <p>One voice can change the world. What will you do with yours?</p>
          <p>Sign up to join a debate today!</p>
        </div>
        <div className="fullwidth bg-blue">
          <div className="container">
            <div className="home-info-block-wrapper">
              <p className="home-info-text">
                OCOV makes talking politics simple and productive. Our debate process is designed to encourage
                open, unfiltered discussions with the opposing party about relevent political topics you care about.
                Each live, online debate is lightly moderated to keep the conversations on track and interruptions brief.
              </p>
              <Divider sx={{ width: 60, marginBottom: 2, marginTop: 2, backgroundColor: "white", height: 3 }} />
              <p className="home-info-text">
                Sign up to join a debate today!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}