import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import Login from '../Modals/Login'
import Subscribe from '../Modals/Subscribe'
import { useWindowSize } from '../../hooks/useWindowSize'
import { IconButton, Tooltip, Drawer, List, Divider, ListItem, ListItemButton,
    ListItemText, Avatar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import Logo from '../../images/ocov-version-2-750.png'
import { headerNavLinks } from '../../services/helper'
import { useVenti } from 'venti'
import './Header.css'
import theme from '../../config'
import PublishSubscribe from 'publish-subscribe-js'

interface Props {
    absolute?: boolean,
    noNav?: boolean
    textStyle?: object
    currentPage?: string
    title: string
}

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

export const Header = (props: Props) => {
    const history = useHistory()
    const ventiState = useVenti()
    const user = ventiState.get(theme.storageKeys.user, {})
    const [width] = useWindowSize()
    const [open, setOpen] = useState<boolean>(false)
    const [cookies, setCookie] = useCookies(['ocov:blogSubscribed'])
    const [subscribeState, setSubscribeState] = useState<number>(0)
    const [openCreateAccount, setOpenCreateAccount] = useState<boolean>(false)
    const [openLogin, setOpenLogin] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('')
    const [openSubscribe, setOpenSubscribe] = useState<boolean>(false)

    useEffect(() => {
        PublishSubscribe.subscribe('SHOW_CREATE_ACCOUNT', () => {
            setOpenCreateAccount(true)
        })
        PublishSubscribe.subscribe('SHOW_LOGIN', () => {
            setOpenLogin(true)
        })

        const qs = window.location.search.replace('?', '').split('&')
        qs.forEach(q => {
            const parts = q.split('=')
            if (parts[0] === 'action' && parts[1] === 'reset-password') {
                setMode('reset-password')
                setOpenLogin(true)
            }
        })

        return () => {
            PublishSubscribe.unsubscribe('SHOW_CREATE_ACCOUNT')
            PublishSubscribe.unsubscribe('SHOW_LOGIN')
        }
    }, [])

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const goToLogin = () => {
        if (user && user.isLoggedIn) {
            history.push('/settings')
        } else {
            setOpenLogin(true)
        }
    }

    return (
        <>
            {openLogin &&
                <Login open={openLogin} setOpen={setOpenLogin} mode={mode} handleClose={() => setMode('')} />
            }
            {openCreateAccount &&
                <Login open={openCreateAccount} setOpen={setOpenCreateAccount} mode={`create-account`} />
            }

            {openSubscribe &&
                <Subscribe
                    open={openSubscribe}
                    setOpen={setOpenSubscribe}
                    subscribeState={subscribeState}
                    setSubscribeState={setSubscribeState}
                    cookies={cookies}
                    setCookie={setCookie}
                />
            }
            <div className='wrapper' style={{ position: props.absolute ? 'absolute' : 'inherit' }}>
                <div className='nav-header'>
                    {!open && width < 1001 && <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        onClick={handleDrawerOpen}
                        style={{ paddingLeft: 20, margin: 0 }}
                    >
                        <MenuIcon />
                    </IconButton>}

                    {subscribeState === 0 && <a href='/'><img className='header-logo' src={Logo} alt="OCOV Logo" /></a>}

                    <nav>
                        <ul>
                            {headerNavLinks.map((link, index) => (
                                <li key={index}>
                                    <a className={props.currentPage === 'home' && link.url === '/' ? 'active'
                                        : props.currentPage === link.url.substring(1).toLowerCase() ? 'active' : ''}
                                        href={link.url}
                                    >
                                        {link.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <div className="header-login">
                        { user && (user.avatar || user.username)
                            ? <Tooltip title="My Profile">
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    onClick={() => goToLogin()}
                                    style={{ marginLeft: 10 }}
                                >
                                    {user && user.avatar
                                        ? <Avatar alt="Profile Image" src={user.avatar} />
                                        : user && user.username
                                            ? <Avatar>{String(user.username[0]).toUpperCase()}</Avatar>
                                            : <PersonIcon fontSize='large' />
                                    }
                                </IconButton>
                            </Tooltip>
                            : <a
                                className='button bg-red'
                                onClick={goToLogin}
                                title="Login">
                                MEMBER LOGIN
                            </a>
                        }
                    </div>
                </div>
            </div>

            {props.title && <div className='wrapper'>
                <div className='header-bg'>
                    <div className='container-header'>
                        <h1>{props.title}</h1>
                    </div>
                </div>
            </div>}
            
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant='persistent'
                anchor='left'
                open={open}
            >
                <DrawerHeader>
                    <img
                        className='header-logo'
                        src={Logo}
                        alt='Logo'
                    />
                    <IconButton onClick={handleDrawerClose}>
                        <CloseIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {headerNavLinks.map((item, index) => {
                        return (
                            <ListItem
                                key={index}
                                component={Link}
                                to={item.url}
                                disablePadding
                                sx={{
                                    '.MuiTypography-root': {
                                        color: props.currentPage === 'home' && item.url === '/'
                                            ? '#bf0a31'
                                            : props.currentPage === item.url.substring(1).toLowerCase() ? '#bf0a31' : '#001e5f',
                                        fontWeight: '500'
                                    },
                                }}
                            >
                                <ListItemButton>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </Drawer>
        </>
    )
}