import React, {useEffect, useState} from 'react'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { getVideos } from '../../services/client'
import { Video } from "../Articles/Article"
import CircularProgress from '@mui/material/CircularProgress'
import './Videos.css'

export default function Videos() {
    const [videos, setVideos] = useState<Video[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        getVideos().then((response: any) => {
            let videosData: any = []
            if (response && response.items && response.items.length) {
                response.items.forEach((item: any) => {
                    videosData.push({
                        id: item.id.videoId,
                        title: item.snippet.title,
                        description: item.snippet.description,
                        img_placeholder: item.snippet.thumbnails.high.url
                    })
                })
                setIsLoading(false)
                setVideos(videosData)
            }
        })
    }, [])

    return (
        <div>
            <Header currentPage="videos" title="Watch Debates" />
            <div className="wrapper">
                <div className="container">
                    { isLoading
                      ? <div className="container-center"><CircularProgress /></div>
                      : videos.map((video:Video, index) => (
                        <div className="cols featured-article" key={index}>
                            <div className="half">
                                <h1 className="debate-header">{video.snippet?.title || `Video ${index + 1}`}</h1>
                            </div>
                            <div className="half">
                                <iframe
                                  width="500"
                                  height="320"
                                  src={`https://www.youtube.com/embed/${video.id}`}
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                  title="Embedded youtube"
                                />
                            </div>
                        </div>
                      ))
                    }
                    <div style={{ display: 'flex', justifyContent: 'center' }}><a className="button mt-20 bg-red" href="/archive" title="Read More">VIEW THE FULL ARCHIVE</a></div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
