import React, { useEffect, useState } from 'react'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { getArticles } from '../../services/client'
import './Blog.css'
import { Article } from "../Articles/Article"
import { stripTags } from "../../services/utils"
import CircularProgress from "@mui/material/CircularProgress"

export default function Blog() {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [articles, setArticles] = useState<Article[]>([])

    useEffect(() => {
        getArticles()
            .then(data => setArticles(data))
            .finally(() => setIsLoading(false))
    }, [])

  return (
      <div>
          <Header currentPage="blog" title="OCOV Blog - Featured Articles" />
          {/*<div className="hero-bg" style={{ backgroundImage: `url(${heroBg})` }}>*/}
          {/*    <div id="blog-title" className="container">*/}
          {/*        <h1>OCOV Blog -</h1>*/}
          {/*        <p>Our blog is now live – Check out our first post, and subscribe to receive new content.</p>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className="wrapper">
              <div className="container">
                  { isLoading
                      ? <div className="container-center"><CircularProgress /></div>
                      : articles.slice(0,3).map((article:Article, index) => (
                          <div className="cols featured-article" key={index}>
                              <div className="half">
                                  <h1 className="debate-header"><a href={`/archive/${article.slug}`}>{article.title}</a></h1>
                                  <p><a href={`/archive/${article.slug}`}>{stripTags(article.body).split(' ').slice(0, 30).join(' ')}...</a></p>
                                  <a className="button mt-20 bg-red" href={`/archive/${article.slug}`} title="Read More">READ MORE</a>
                              </div>
                              <div className="half">
                                  <a href={`/archive/${article.slug}`}><img className="featured-blog-image" src={article.hero_image} alt={article.title}/></a>
                              </div>
                          </div>
                        ))
                  }
                  <div style={{ display: 'flex', justifyContent: 'center' }}><a className="button mt-20 bg-red" href="/archive" title="Read More">VIEW THE FULL ARCHIVE</a></div>
              </div>
          </div>
          <Footer />
      </div>
  )
}
