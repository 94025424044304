import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useVenti } from 'venti'
import CircularProgress from '@mui/material/CircularProgress'
import { Footer } from '../../../components/Footer'
import { Header } from '../../../components/Header'
import Comment from '../../../components/Comment'
import CommentsBlock from '../../../components/CommentsBlock'
import Share from '../../../components/Modals/Share'
import Login from '../../../components/Modals/Login'
import { getArticleBySlug } from '../../../services/client'
import { ReactComponent as GooglePlayBadge } from '../../../assets/images/google-play-badge.svg'
import { ReactComponent as DownloadOnAppStoreBadge } from '../../../assets/images/Download_on_the_App_Store_Badge_US-UK_blk_092917.svg'
import {
    addArticleComment, likeArticleComment, likeArticleReplyComment, replyToArticleComment
} from '../../../services/client'
import theme from '../../../config'
import './Article.css'


export type Article = {
    comments: any
    slug: string
    title: string
    body: string
    hero_image: string
    hero_image_copyright: string
    id: number | null
}

export type Video = {
    id: {
        videoId: string
    }
    snippet: {
        title: string
        description: string
        thumbnails: {
            img_placeholder: string
        }
    }
}

type articleParams = {
    slug: string
}

const blankArticle = {
    slug: '',
    title: '',
    body: '',
    hero_image: '',
    hero_image_copyright: '',
    comments: [],
    id: null
}

export default function Article() {
    const ventiState = useVenti()
    const authToken = ventiState.get(theme.storageKeys.authToken, {})
    const user = ventiState.get(theme.storageKeys.user, {})
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { slug } = useParams<articleParams>()
    const [openLogin, setOpenLogin] = useState<boolean>(false)
    const [article, setArticle] = useState<Article>(blankArticle)
    const [width, setWidth] = useState<number>(window.innerWidth)
    const [commentText, setCommentText] = useState<string>('')
    const [replyText, setReplyText] = useState<string>('')
    const [commentId, setCommentId] = useState<number | null>(null)
    const [replyId, setReplyId] = useState<number | null>(null)
    const [shareOpen, setShareOpen] = useState<boolean>(false)
    const [shareData, setShareData] = useState({
        link: '',
        name: ''
    })

    useEffect(() => {
        getArticleBySlug(slug)
            .then((res: Article) => setArticle(res))
            .finally(() => setIsLoading(false))
            .catch(err => alert(err))
    }, [])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    const isMobile = width <= 768

    const handlePostComment = () => {
        user.isLoggedIn ?
            addArticleComment(article.id, String(commentText).trim(), authToken)
                .then((res) => {
                    let comments = article.comments.slice()
                    res.user = {
                        username: user.username,
                        avatar: user.avatar
                    }

                    comments.unshift(Object.assign({}, res, { replies: [], num_likes: 0, num_dislikes: 0 }))
                    setArticle({
                        ...article,
                        comments: comments
                    })
                    setCommentText('')
                }) :
            setOpenLogin(true)
    }

    const handlePostReply = (commentId: number, comment: any, replyId: null | number, resetId: any, resetMesssageText: any) => {
        user.isLoggedIn ?
            replyToArticleComment(commentId, (comment && comment?.user?.id) || user?.id, Number(replyId), String(replyText).trim(), authToken)
                .then((res) => {
                    let comments = article.comments.slice()
                    let comment = comments.find((item: any) => item.id === commentId)
                    res.user = {
                        username: user.username,
                        avatar: user.avatar
                    }
                    comment.replies.push(Object.assign({}, res, { num_likes: 0, num_dislikes: 0 }))
                    setArticle({
                        ...article,
                        comments: comments
                    })
                    resetId(null)
                    resetMesssageText('')
                }) :
            setOpenLogin(true)
    }

    const handleLikeComment = (comment: any) => {
        const like_value = comment.like_value === 1 ? 0 : comment.like_value === -1 ? 1 : 1

        user.isLoggedIn ?
            likeArticleComment(comment.id, like_value, authToken).then(() => {
                let commentsData = article.comments.slice()
                let commentData = commentsData.find((item: any) => item.id === comment.id)
                commentData.num_likes = like_value === 1 ? Number(commentData.num_likes || 0) + 1 : Number(commentData.num_likes || 0) - 1
                commentData.num_dislikes = commentData.like_value === -1 ? Number(commentData.num_dislikes) - 1 : commentData.num_dislikes
                commentData.like_value = like_value
                setArticle({ ...article, comments: commentsData })
            }) :
            setOpenLogin(true)
    }

    const handleDislikeComment = (comment: any) => {
        const like_value = comment.like_value === 1 ? -1 : comment.like_value === -1 ? 0 : -1

        user.isLoggedIn ?
            likeArticleComment(comment.id, like_value, authToken).then(() => {
                let commentsData = article.comments.slice()
                let commentData = commentsData.find((item: any) => item.id === comment.id)
                commentData.num_likes = commentData.like_value === 1 ? Number(commentData.num_likes) - 1 : commentData.num_likes
                commentData.num_dislikes = like_value === -1 ? Number(commentData.num_dislikes || 0) + 1 : Number(commentData.num_dislikes || 0) - 1
                commentData.like_value = like_value
                setArticle({ ...article, comments: commentsData })
            }) :
            setOpenLogin(true)
    }

    const handleLikeReplyComment = (comment: any, reply: any) => {
        const like_value = reply.like_value === 1 ? 0 : reply.like_value === -1 ? 1 : 1

        user.isLoggedIn ?
            likeArticleReplyComment(reply.id, like_value, authToken).then(() => {
                let commentsData = article.comments.slice()
                let selectedComment = commentsData.find((item: any) => item.id === comment.id)
                let repliesData = selectedComment.replies.slice()
                let replyData = repliesData.find((item: any) => item.id === reply.id)
                replyData.num_likes = like_value === 1 ? Number(replyData.num_likes || 0) + 1 : Number(replyData.num_likes || 0) - 1
                replyData.num_dislikes = replyData.like_value === -1 ? Number(replyData.num_dislikes) - 1 : replyData.num_dislikes
                replyData.like_value = like_value
                setArticle({ ...article, comments: commentsData })
            }) :
            setOpenLogin(true)
    }

    const handleDislikeReplyComment = (comment: any, reply: any) => {
        const like_value = comment.like_value === 1 ? -1 : comment.like_value === -1 ? 0 : -1

        user.isLoggedIn ?
            likeArticleReplyComment(comment.id, like_value, authToken).then(() => {
                let commentsData = article.comments.slice()
                let selectedComment = commentsData.find((item: any) => item.id === comment.id)
                let repliesData = selectedComment.replies.slice()
                let replyData = repliesData.find((item: any) => item.id === reply.id)
                replyData.num_likes = replyData.like_value === 1 ? Number(replyData.num_likes) - 1 : replyData.num_likes
                replyData.num_dislikes = like_value === -1 ? Number(replyData.num_dislikes || 0) + 1 : Number(replyData.num_dislikes || 0) - 1
                replyData.like_value = like_value
                setArticle({ ...article, comments: commentsData })
            }) :
            setOpenLogin(true)
    }

    return (
        <>
            <Share
                open={shareOpen}
                setOpen={setShareOpen}
                text={`Share`}
                link={shareData.link}
                name={shareData.name}
            />
            {openLogin &&
				<Login open={openLogin} setOpen={setOpenLogin} />
            }
            <div>
                <Header currentPage={'archive'} title={article.title} />
                <section className="wrapper">
                    {isLoading
                        ? <div className="container-center"><CircularProgress /></div>
                        : <article className="container flex-reverse">
                            <div className="left-col">
                                <p dangerouslySetInnerHTML={{ __html: article.body }} />
                            </div>
                            <div className="right-col">
                                <img src={article.hero_image} alt={article.title} />
                                <span className="copyright">{article.hero_image_copyright}</span>
                            </div>
                        </article>
                    }
                </section>
                {article && !isLoading && (
                    <div className='article-comments-wrapper'>
                        <p className='article-comment-title'>Comments</p>
                        <Comment
                            isReply={false}
                            title='Write Comment'
                            commentName={article.slug}
                            text={commentText}
                            textChange={setCommentText}
                            handlePostComment={handlePostComment}
                            id={article.id}
                            authToken={authToken}
                        />
                        <CommentsBlock
                            comments={article?.comments}
                            handleLikeComment={handleLikeComment}
                            handleDislikeComment={handleDislikeComment}
                            commentName={article.slug}
                            id={article.id}
                            authToken={authToken}
                            handlePostReply={handlePostReply}
                            handleLikeReplyComment={handleLikeReplyComment}
                            handleDislikeReplyComment={handleDislikeReplyComment}
                            setShareOpen={setShareOpen}
                            setShareData={setShareData}
                            replyText={replyText}
                            setReplyText={setReplyText}
                            commentId={commentId}
                            setCommentId={setCommentId}
                            replyId={replyId}
                            setReplyId={setReplyId}
                        />
                    </div>
                )}
                <section className="article-footer">
                    <p>Download our app to vote now!</p>
                    <div className="app-links">
                        <a href="https://play.google.com/store/apps/details?id=com.ocov.vote" target="_blank" rel="noopener"><GooglePlayBadge /></a>
                        <a href="https://apps.apple.com/us/app/our-country-our-vote/id1490201042" target="_blank" rel="noopener"><DownloadOnAppStoreBadge /></a>
                    </div>
                    <p>You can also watch all our debates via the OCOV app or on <a className={!isMobile ? 'underline' : ''} href="https://www.youtube.com/channel/UC2d0q2XBKTckZLz1a6hshKw" target="_blank" rel="noopener">YouTube</a></p>
                    <p>If you would like to share your voice, consider participating in a debate...</p>
                    <a className="button" title="Sign up for a debate" href="/debate">SIGN UP FOR A DEBATE</a>
                </section>
                <Footer />
            </div>
        </>
    )
}