import { useState, useReducer, useEffect } from 'react'
import { useVenti } from 'venti'
import { isEqual } from 'lodash'
import { Tabs, Tab, Card, CardContent, Button } from '@mui/material'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { a11yProps } from '../../services/helper'
import MyProfile from '../../components/MyProfile/MyProfile'
import Demographics from '../../components/Demographics/Demographics'
import { getDemographics, saveDemographics } from '../../services/client'
import theme from '../../config'
import './Settings.css'
import { styled } from '@mui/material/styles'

const StyledTab = styled((props: any) => (
  <Tab {...props} />
))(() => ({
  color: '#121212',
  fontWeight: 'bold',
  fontSize: 16,
  '&.Mui-selected': {
    color: '#d32f2f',
  },
}));

function TabPanel(props: any): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className='mt-5'>{children}</div>
      )}
    </div>
  )
}

export default function Settings() {
  const ventiState = useVenti()
  const authToken = ventiState.get(theme.storageKeys.authToken, {})
  const [tab, setTab] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [initialState, setInitialState] = useState({
    birthdate: '',
    country: '',
    economic_ideology: '',
    education: '',
    fiscal_views: '',
    gender: '',
    income: '',
    military_service: '',
    party: '',
    race: '',
    registered_to_vote: '',
    religion: '',
    sexual_orientation: '',
    social_views: '',
    state: '',
    us_citizen: '',
    zipcode: '',
    county: '',
  })
  const [demographicsState, setDemographicsState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }), {
      birthdate: '',
      country: '',
      economic_ideology: '',
      education: '',
      fiscal_views: '',
      gender: '',
      income: '',
      military_service: '',
      party: '',
      race: '',
      registered_to_vote: '',
      religion: '',
      sexual_orientation: '',
      social_views: '',
      state: '',
      us_citizen: '',
      zipcode: '',
      county: '',
    })

  const isSaveFormActive = !isEqual(initialState, demographicsState)

  const handleTabChange = (event: any, value: number) => {
    setTab(value)
  }

  const [formMessage, setFormMessage] = useState({
    message: '',
    isError: false
  })

  useEffect(() => {
    if (authToken) {
      getDemographics(authToken)
        .then((demographics) => {
          setInitialState(demographics)
          setDemographicsState(demographics)
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }, [authToken])

  const handleInfoUpdate = () => {
    setFormMessage({
      message: '',
      isError: false
    })
    setLoading(true)
    saveDemographics(demographicsState, authToken)
      .then(() => {
        setInitialState(demographicsState)
        setFormMessage({
          message: 'Your settings have been updated',
          isError: false
        })
        setLoading(false)
      })
      .catch((err) => {
        setFormMessage({
          message: err.data || 'Server Error',
          isError: false
        })
        setLoading(false)
      })
  }

  return (
    <div>
      <Header currentPage='settings' title='My Profile' />
      <div className="wrapper">
        <div className="container">
          {tab === 1 && <div className='settings-button-wrapper'>
            <Button
              type='submit'
              variant='contained'
              color='error'
              className='settings-save-button'
              onClick={handleInfoUpdate}
              disabled={!isSaveFormActive || loading}
            >
              Save
            </Button>
            {formMessage.message && <p className={`settings-message ${formMessage.isError ? 'error' : 'success'}`}>{formMessage.message}</p>}
          </div>}
          <Tabs
            variant='scrollable'
            value={tab}
            onChange={handleTabChange}
            aria-label='Tabs'
            sx={{
              color: '#121212',
              fontSize: 16,
              '.MuiTabs-indicator': {
                backgroundColor: '#d32f2f',
              },
            }}
          >
            <StyledTab label='My Profile' {...a11yProps(0)} />
            <StyledTab label='Demographics' {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Card>
              <CardContent>
                <MyProfile />
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Card>
              <CardContent>
                <Demographics
                  demographicsState={demographicsState}
                  setDemographicsState={setDemographicsState}
                />
              </CardContent>
            </Card>
          </TabPanel>
        </div>
      </div>
      <Footer />
    </div>
  )
}