import React, { useMemo, useState } from 'react'
import PublishSubscribe from 'publish-subscribe-js'
import { Input } from '@material-ui/core'
import { Tabs, Tab, Card, CardContent, IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import Polls from '../../components/Polls/Polls'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { getCategories } from '../../services/client'
import { a11yProps } from '../../services/helper'
import './Polls.css'
import { styled } from '@mui/material/styles'
import { useQuery } from 'react-query'
import CircularProgress from '@mui/material/CircularProgress'

const StyledTab = styled((props: any) => (
    <Tab {...props} />
))(() => ({
    color: '#121212',
    fontWeight: 'bold',
    fontSize: 16,
    '&.Mui-selected': {
        color: '#d32f2f',
    },
}));

type Category = {
    id: string,
    name: string
}

function TabPanel(props: any): JSX.Element {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className='mt-5'>{children}</div>
            )}
        </div>
    )
}

export default function Home() {
    const [tab, setTab] = useState<number>(0)
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const { data = [], isLoading } = useQuery<Category[]>('categories', () => getCategories())
    const categories = useMemo(() => {
        const dataCopy = [...data]
        dataCopy.unshift({ name: 'Latest', id: 'latest' })
        dataCopy.unshift({ name: 'Trending', id: '0' })
        return dataCopy
    }, [data])

    const handleTabChange = (event: any, value: number) => {
        setIsSearchOpen(false)
        setSearchText('')
        setTab(value)
    }

    const updateSearch = (search: string) => {
        setSearchText(search)

        if (search.length >= 3) {
            PublishSubscribe.publish('SEARCH_POLL', { search, category: categories[tab] })
        }

        if (search.length === 0) {
            PublishSubscribe.publish('RESET_SEARCH_POLL')
        }

    }

    const hideSearch = () => {
        setIsSearchOpen(!isSearchOpen)
        setSearchText('')
        PublishSubscribe.publish('RESET_SEARCH_POLL')
    }

    return (
        <div>
            <Header currentPage='polls' title="Polls" />
            <div className='wrapper'>
                {isLoading
                    ? <div className='container-center'><CircularProgress /></div>
                    : <div className='container'>
                        {isSearchOpen ?
                            <div className='search-polls-wrapper'>
                                <IconButton disabled>
                                    <SearchIcon />
                                </IconButton>
                                <Input
                                    placeholder={`Search ${categories[tab].name} polls`}
                                    className='search-polls-fields'
                                    id='search-polls'
                                    autoFocus={true}
                                    value={searchText}
                                    onChange={(e) => updateSearch(e.target.value)}
                                />
                                <Tooltip title="Close">
                                    <IconButton
                                        onClick={() => hideSearch()}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> :
                            <div className='search-button-wrapper'>
                                <Tooltip title="Search Polls">
                                    <IconButton
                                        onClick={() => setIsSearchOpen(!isSearchOpen)}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        <Tabs
                            variant='scrollable'
                            value={tab}
                            onChange={handleTabChange}
                            aria-label='Tabs'
                            sx={{
                                '.MuiTabs-indicator': {
                                    backgroundColor: '#d32f2f',
                                },
                            }}
                        >
                            {categories.map((category, index) => (
                                <StyledTab key={index} label={category.name} {...a11yProps(index)} />
                            ))}
                        </Tabs>

                        {categories.map((category: any, index: number) => (
                            <TabPanel key={index} value={tab} index={index}>
                                <Card>
                                    <CardContent style={{ minHeight: 400 }}>
                                        <Polls id={category.id} />
                                    </CardContent>
                                </Card>
                            </TabPanel>

                        ))}
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}