import React from 'react'
import './About.css'
import { Header } from '../../components/Header'
import AboutImage from '../../assets/images/about-ocov.png'
import { Footer } from '../../components/Footer'
import PublishSubscribe from 'publish-subscribe-js'
import { Typography } from '@mui/material'

export default function About() {

  const openSignUpModal = () => {
    PublishSubscribe.publish('SHOW_CREATE_ACCOUNT')
  }

  return (
    <>
      <Header currentPage="about" title="Our Story" />
      <section className="about">
        <div className="container">
          <img className="aboutImage mb-20" src={AboutImage} alt="Who is OCOV?" />
          <p className="aboutDescription">
            In this country, it’s always politicians talking to America, but never the other way around. We’re here to change that.
            <br /><br />
            OCOV is a social community for devoted Americans who want to give power to their voice. Here, it’s Americans talking to America, where you can ignite the change you want to see by:
            <br /><br />
          </p>
          <ul className="aboutList">
            <li>Voting on the top issues related to current political landscape</li>
            <li>Debating face-to-face live via video with the opposing party</li>
            <li>Catching up on relevant news from the latest blog</li>
            <li>Connecting with other passionate, informed Americans</li>
          </ul>
          <p className="aboutDescription">
            <br /><br />
            As concerned citizens, we have a relentless thirst to drive change in the way politics are done online.
            That’s why we are handing the podium to you to help build a better world and ensure your comments are always heard directly
            by the public. We are politically neutral and unfiltered, so you can speak your mind with passion freely without the worry
            of being banned.
            <br /><br />
            Come with your passion and drive, your intellect and your patience, and become a part of the future of how politics are done online.
          </p>
          <div className="row">
            <div className="becomeAMemberContainer">
              <a
                className="button becomeAMember bg-red"
                onClick={openSignUpModal}
                title="Create a Profile">
                BECOME A MEMBER
              </a>
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <Typography variant={`h4`} style={{ marginBottom: 10 }}>FAQ</Typography>
              <br />
              <Typography variant={`h5`}>Who can use OCOV?</Typography>
              <p>Any American citizen who is registered to vote can join the OCOV community. We strive to make OCOV an inclusive and welcoming
                platform for every informed American.</p>

              <Typography variant={`h5`}>Can other users see my political preferences?</Typography>
              <p>We apply the same manners around bringing up political preferences at a dinner party - we don’t. We respect your privacy online
                so that you can feel comfortable and confident talking about any political topic with any other OCOV member.</p>

              <Typography variant={`h5`}>How do you use my info?</Typography>
              <p>The OCOV app does not use or share your personal profile information with any other members or affiliate programs. When you
                sign up for a debate, we use the information you provide to help our moderators best match you with other debaters.</p>
            </div>
          </div>
          <div className="row">
            <div className="becomeAMemberContainer">
              <a
                className="button becomeAMember bg-red"
                onClick={openSignUpModal}
                title="Create a Profile">
                BECOME A MEMBER
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )

}
