import moment from 'moment'

/**
 * @param {string} email
 * @returns {boolean} - return TRUE if valid
 */
export const isEmailValid = function(email: string) {
	return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(email.toLowerCase())
}

/**
 * @param {string} phone
 * @returns {boolean} - return TRUE if valid
 */
export const isPhoneNumberValid = function(phone: string) {
	return /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone)
}

/**
 * @param {string} ssn
 * @returns {boolean} - return TRUE if valid
 */
export const isSSNValid = function(ssn: string) {
	return /^\d{3}-?\d{2}-?\d{4}$/.test(ssn)
}

export const hasEmptyProperties = (obj: any) => {
	if (typeof obj !== 'object') return true
	const values = Object.values(obj)
	console.log(values)
	for (let i in values) {
		if (!values[i]) return true
	}
	return false;

}

/**
 * @param {string} dob
 * @returns {boolean} - return TRUE if valid
 */
export const isDOBValid = function(dob: string) {
	if (dob.length < 10) return false
	const m = moment(dob, "MM/DD/YYYY")
	const now = moment()
	const diff = now.diff(m, "seconds")
	return (dob.length === 10 && !isNaN(diff) && diff >= 567648000 )
}

export const isDateValid = function(dob: string) {
	if (dob.length < 10) return false
	const m = moment(dob, "MM/DD/YYYY")
	const now = moment()
	const diff = now.diff(m, "seconds")
	return (dob.length === 10 && !isNaN(diff) && diff >= 0 )
}

export const isPositiveInt = function(value: any) {
	return (value && !isNaN(value))
}

export const isValidYear = function(value: number) {
	return (value && !isNaN(value) && value >= 1500 && value <= new Date().getFullYear())
}

export const isValidYears = function(value: number) {
	return (value > -1 && !isNaN(value))
}

export const isValidMonths = function(value: number) {
	return (!isNaN(value) && value > -1 && value < 12)
}

export const isValidCurrency = function(value: any) {
	return (parseFloat(value) >= 0 && !isNaN(parseFloat(value)))
}
export const isValidDecimal = function(value: number) {
	return (value > -1 && !isNaN(value))
}

export const validationMessages = {
	'phone': 'Please enter a valid phone number',
	'email': 'Please enter a valid email address',
	'ssn': 'Please enter a valid Social Security Number',
	'date': 'Please enter a valid date in mm/dd/yyyy format',
	'dob': `Please enter a valid birthdate before ${moment().subtract(18, "years").toDate().toLocaleDateString()}`,
	'year': 'Please enter a valid year',
	'years': 'Please enter a valid number of years',
	'months': 'Please enter a valid number of months',
	'positive': 'Please enter a value greater than 0',
	'required': 'Please enter a value',
	'alpha': 'Please enter a value',
	'numeric': 'Please enter a value',
	'alphanumeric': 'Please enter a value',
	'password': 'Password does not meet the criterion'
}

export const googleAutocomplete = async (text: string) =>
	new Promise((resolve, reject) => {
		try {
			// @ts-ignore
			new window.google.maps.places.AutocompleteService().getPlacePredictions(
				{ input: text, types: ['geocode'], componentRestrictions: { country: "us" } },
				resolve
			)
		} catch (e) {
			reject(e)
		}
	})

export const geocode = async (placeId: any) =>
	new Promise((resolve, reject) => {
		try {
			// @ts-ignore
			new window.google.maps.Geocoder().geocode({ placeId }, resolve)
		} catch (e) {
			reject(e)
		}
	})

/**
 * @param {string} key
 * @param {string} data
 */
export const setItemAsyncStorage = async (key: string, data: string) => {
	try {
		await localStorage.setItem(key, data)
	} catch (error) {
		console.log('ERROR: setDataAsyncStorage ', key, data, error)
	}
}

/**
 * @param {string} key
 */
export const getItemAsyncStorage = async (key: string) => {
	try {
		return await localStorage.getItem(key)
	} catch (error) {
		console.log('ERROR: getItemAsyncStorage ', key, error)
		return null
	}
}

export const headerNavLinks = [
	{ name: "HOME", url: "/" },
	{ name: "POLLS", url: "/polls" },
	{ name: "BLOG", url: "/blog" },
	{ name: "WATCH DEBATES", url: "/videos" },
	// { name: "THE ARCHIVE", url: "/archive" },
	{ name: "OUR STORY", url: "/about" },
	{ name: "DEBATE", url: "/debate" },
]

export const states =  [
	{ 'label': 'Alabama', 'value': 'AL' },
	{ 'label': 'Alaska', 'value': 'AK' },
	{ 'label': 'American Samoa', 'value': 'AS' },
	{ 'label': 'Arizona', 'value': 'AZ' },
	{ 'label': 'Arkansas', 'value': 'AR' },
	{ 'label': 'California', 'value': 'CA' },
	{ 'label': 'Colorado', 'value': 'CO' },
	{ 'label': 'Connecticut', 'value': 'CT' },
	{ 'label': 'Delaware', 'value': 'DE' },
	{ 'label': 'District of Columbia', 'value': 'DC' },
	{ 'label': 'States of Micronesia', 'value': 'FM' },
	{ 'label': 'Florida', 'value': 'FL' },
	{ 'label': 'Georgia', 'value': 'GA' },
	{ 'label': 'Guam', 'value': 'GU' },
	{ 'label': 'Hawaii', 'value': 'HI' },
	{ 'label': 'Idaho', 'value': 'ID' },
	{ 'label': 'Illinois', 'value': 'IL' },
	{ 'label': 'Indiana', 'value': 'IN' },
	{ 'label': 'Iowa', 'value': 'IA' },
	{ 'label': 'Kansas', 'value': 'KS' },
	{ 'label': 'Kentucky', 'value': 'KY' },
	{ 'label': 'Louisiana', 'value': 'LA' },
	{ 'label': 'Maine', 'value': 'ME' },
	{ 'label': 'Marshall Islands', 'value': 'MH' },
	{ 'label': 'Maryland', 'value': 'MD' },
	{ 'label': 'Massachusetts', 'value': 'MA' },
	{ 'label': 'Michigan', 'value': 'MI' },
	{ 'label': 'Minnesota', 'value': 'MN' },
	{ 'label': 'Mississippi', 'value': 'MS' },
	{ 'label': 'Missouri', 'value': 'MO' },
	{ 'label': 'Montana', 'value': 'MT' },
	{ 'label': 'Nebraska', 'value': 'NE' },
	{ 'label': 'Nevada', 'value': 'NV' },
	{ 'label': 'New Hampshire', 'value': 'NH' },
	{ 'label': 'New Jersey', 'value': 'NJ' },
	{ 'label': 'New Mexico', 'value': 'NM' },
	{ 'label': 'New York', 'value': 'NY' },
	{ 'label': 'North Carolina', 'value': 'NC' },
	{ 'label': 'North Dakota', 'value': 'ND' },
	{ 'label': 'Northern Mariana Islands', 'value': 'MP' },
	{ 'label': 'Ohio', 'value': 'OH' },
	{ 'label': 'Oklahoma', 'value': 'OK' },
	{ 'label': 'Oregon', 'value': 'OR' },
	{ 'label': 'Palau', 'value': 'PW' },
	{ 'label': 'Pennsylvania', 'value': 'PA' },
	{ 'label': 'Puerto Rico', 'value': 'PR' },
	{ 'label': 'Rhode Island', 'value': 'RI' },
	{ 'label': 'South Carolina', 'value': 'SC' },
	{ 'label': 'South Dakota', 'value': 'SD' },
	{ 'label': 'Tennessee', 'value': 'TN' },
	{ 'label': 'Texas', 'value': 'TX' },
	{ 'label': 'Utah', 'value': 'UT' },
	{ 'label': 'Vermont', 'value': 'VT' },
	{ 'label': 'Virgin Islands', 'value': 'VI' },
	{ 'label': 'Virginia', 'value': 'VA' },
	{ 'label': 'Washington', 'value': 'WA' },
	{ 'label': 'West Virginia', 'value': 'WV' },
	{ 'label': 'Wisconsin', 'value': 'WI' },
	{ 'label': 'Wyoming', 'value': 'WY' }
]

export const a11yProps = (index: number) => {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	}
}

export const getCreatedDate = (date: string, type: string) => {
	if (type === 'post_view') {
		return moment(date).format('DD MMMM YYYY')
	}

	if (type === 'time') {
		const dateDiff = moment().diff(moment(new Date(date)), 'day')

		if (dateDiff === 0) {
			return moment(new Date(date)).format('[Today at] hh:mm a')
		}

		if (dateDiff === 1) {
			return moment(new Date(date)).format('[Yesterday at] hh:mm a')
		}

		return moment(new Date(date)).format('MMMM DD, YYYY [at] hh:mm a')
	}

	const duration = moment.duration(moment(new Date(date)).diff(moment(new Date())))
	const hours = Math.abs(Math.round(duration.asHours()))
	const seconds = Math.abs(Math.round(duration.asSeconds()))
	const minutes = Math.abs(Math.round(duration.asMinutes()))
	const days = Math.abs(Math.round(duration.asDays()))
	const years = Math.abs(Math.round(duration.asYears()))

	if (type === 'comment') {
		if (seconds !== 0 && seconds <= 60) {
			return '1m'
		}

		if (hours !== 0 && hours <= 23) {
			return `${hours}h`
		}

		if (minutes !== 0 && minutes <= 59) {
			return `${minutes}m`
		}

		if (days !== 0 && days < 7) {
			return `${days}d`
		}

		if (days === 7 || days === 8) {
			return '1w'
		}

		if (days !== 0 && days <= 365) {
			let weeks = Math.round(days / 7)

			if (weeks > 4) {
				return `${Math.round(weeks / 4)}mo`
			} else {
				return `${weeks}w`
			}
		}

		if (years !== 0) {
			return `${years}y`
		}
	}

	if (seconds !== 0 && seconds <= 60) {
		return '1m ago'
	}

	if (hours !== 0 && hours <= 23) {
		return `${hours}h ago`
	}

	if (minutes !== 0 && minutes <= 59) {
		return `${minutes}m ago`
	}

	if (days !== 0 && days < 7) {
		return `${days}d ago`
	}

	if (days === 7 || days === 8) {
		return 'last week'
	}

	if (days !== 0 && days <= 365) {
		let weeks = Math.round(days / 7)

		if (weeks > 4) {
			return `${Math.round(weeks / 4)}mo ago`
		} else {
			return `${weeks}w ago`
		}
	}

	if (years !== 0) {
		return `${years}y ago`
	}

	return moment(date).format('MMMM DD, YYYY')
}

export const getCommentDate = (date: string) => {
	return moment(new Date(date)).format('MMMM DD, YYYY [at] hh:mm a')
}
