import { useState, useReducer, useEffect } from 'react'
import { FormControl, InputLabel, Input, FormHelperText,
    CircularProgress } from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { isEmailValid } from '../../services/helper'
import Logo from '../../images/ocov-version-2-750.png'
import theme from '../../config'
import { isUsernameInUse, isEmailInUse, createAccount } from '../../services/client'
import './CreateAccount.css'
import { userLoginAction } from "../../store/user"

interface Props {
    setForm: any,
    handleClose: any
}

function CreateAccount(props: Props) {
    const [message, setMessage] = useState({ isError: false, messageText: '' })
    const [formInput, setFormInput] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            displayName: '',
            email: '',
            password: '',
        }
    )
    const [terms, setTerms] = useState<boolean>(false)
    const [displayNamelValidation, setDisplayNamelValidation] = useState({
        isValid: false,
        errorText: ''
    })
    const [emailValidation, setEmailValidation] = useState({
        isValid: false,
        errorText: ''
    })
    const [loading, setLoading] = useState<boolean>(false)

    const enabledSignUpButton =
        displayNamelValidation.isValid &&
        emailValidation.isValid &&
        isEmailValid(formInput.email) &&
        formInput.password.length >= theme.validation.passwordLength &&
        terms

    const displayNameOnBlur = () => {
        formInput.displayName.length < theme.validation.userName &&
        setDisplayNamelValidation({
            isValid: false,
            errorText: 'Name should be more than 3 symbols'
        })
    }

    const emailOnBlur = () => setEmailValidation({
        isValid: isEmailValid(formInput.email),
        errorText: 'Email is not valid'
    })

    const handleSubmit = (evt: any) => {
        evt.preventDefault()

        setLoading(true)
        createAccount(formInput)
            .then(async (login) => {
                if (!login) {
                    setMessage({
                        isError: true,
                        messageText: 'Please try again'
                    })
                } else {
                    const authToken = login.token
                    const user = Object.assign({}, login.user)
                    await userLoginAction(user, authToken)
                    setLoading(false)
                    props.handleClose()
                }
            })
            .catch((error) => {
                setMessage({
                    isError: true,
                    messageText: (error && error.data) || 'Please try again'
                })
                setLoading(false)
            })
    }

    const handleInput = (evt: any) => {
        if (message.isError) setMessage({ isError: false, messageText: '' })
        const name = evt.target.id
        const newValue = evt.target.value
        setFormInput({ [name]: name === "displayName" ? newValue.replace(/[^a-zA-Z0-9_]/g, '') : newValue })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            formInput.displayName.length >= theme.validation.userName &&
            isUsernameInUse(formInput.displayName)
                .then((response) => response.valid ?
                    setDisplayNamelValidation({ isValid: response.valid, errorText: '' }) :
                    setDisplayNamelValidation({ isValid: response.valid, errorText: 'Display Name in use' })
                )
        }, 500)

        return () => clearTimeout(timer)
    }, [formInput.displayName])


    const passedAll = !theme.validation.passwordRequirements.filter(
        (requirement) => !requirement.regex.test(formInput.password)
    ).length

    useEffect(() => {
        if (passedAll) {
            const timer = setTimeout(() => {
                isEmailInUse(formInput.email)
                    .then((response) => response.valid ?
                        setEmailValidation({ isValid: response.valid, errorText: '' }) :
                        setEmailValidation({ isValid: response.valid, errorText: 'Email in use' })
                    )
            }, 500)

            return () => clearTimeout(timer)
        }
    }, [passedAll, formInput])

    return (
        <div className="auth-wrapper">
            <div className="auth-content-wrapper">
                <img className="auth-logo" src={Logo} alt="logo" />
                <h1 className="auth-title">Create account</h1>
                <form onSubmit={handleSubmit} className="auth-form-wrapper">
                    <FormControl className="auth-form-input">
                        <InputLabel htmlFor="email">Display Name</InputLabel>
                        <Input
                            id="displayName"
                            onBlur={displayNameOnBlur}
                            value={formInput.displayName}
                            aria-describedby="my-helper-text"
                            onChange={handleInput}
                        />
                        {!displayNamelValidation.isValid && (
                            <FormHelperText className="error-text">
                                {displayNamelValidation.errorText}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl className="auth-form-input">
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input
                            id="email"
                            onBlur={emailOnBlur}
                            defaultValue={formInput.email}
                            aria-describedby="my-helper-text"
                            onChange={handleInput}
                        />
                        {!emailValidation.isValid && (
                            <FormHelperText className="error-text">
                                {emailValidation.errorText}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl className="auth-form-input">
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            id="password"
                            type="password"
                            defaultValue={formInput.password}
                            onChange={handleInput}
                        />
                        <div>
                            {passedAll ? (
                                <p className="change-password-title">{`\u2713 All password requirements have been met`}</p>
                            ) : (
                                <>
                                    <p className="change-password-title">
                                        Your password must meet the following requirements...
                                    </p>

                                    {theme.validation.passwordRequirements.map((requirement, i) => {
                                        let icon = `\u2022`;
                                        const pass = requirement.regex.test(formInput.password);
                                        if (pass) icon = `\u2713`;
                                        return (
                                            <p
                                                key={requirement.key + i}
                                                className={`requirement-text ${pass && "pass-color"}`}
                                            >{`${icon} ${requirement.label}`}</p>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </FormControl>
                    <Button
                        type="submit"
                        variant="contained"
                        color="error"
                        className="auth-form-button"
                        disabled={!enabledSignUpButton || loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Register'}
                    </Button>
                </form>
                {message && <p className={`message ${message.isError ? 'error' : 'success'}`}>{message.messageText}</p>}
                <div className='terms-wrapper'>
                    <Checkbox
                        checked={terms}
                        onChange={() => setTerms(!terms)}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                    <p className='terms-text'>
                        By registering, you agree to the&nbsp;
                        <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            underline="hover"
                            href={theme.uris.terms}
                            className="auth-link"
                        >
                            Terms of Service&nbsp;
                        </Link>
                        and&nbsp;
                        <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href={theme.uris.privacy}
                            className="auth-link"
                            underline="hover"
                        >
                            Privacy Policy
                        </Link>
                    </p>
                </div>
                <Divider sx={{ width: "100%" }}>or</Divider>
                <div className="auth-link-wrapper">
                    <Button onClick={() => props.setForm("login")}>
                        Sign In
                    </Button>
                </div>
            </div>
        </div >
    )
}

export default CreateAccount