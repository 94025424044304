import React, { useEffect, useState } from 'react'
import '../assets/styles/normalize.css'
import '../index.css'
import { state } from 'venti'
import { initialUserState } from '../store/user'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { getItemAsyncStorage } from '../services/helper'
import PrivateRoute from '../routes/PrivateRoute'
import themeConfig from '../config'
import { QueryClient, QueryClientProvider } from 'react-query'

// @ts-ignore
import HttpsRedirect from 'react-https-redirect'
import { CookiesProvider } from 'react-cookie'

// Pages
import Home from './Home'
import Polls from './Polls'
import Blog from './Blog'
import Videos from './Videos'
import About from './About'
import Debate from './Debate'
import Contact from './Contact'
import Privacy from './Privacy'
import Articles from './Articles'
import Article from './Articles/Article'
import Settings from './Settings'
import Poll from './Poll'
import RequestAccountDeletion from './RequestAccountDeletion'
const { storageKeys } = themeConfig
const queryClient = new QueryClient()

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#DD3333',
        },
    },
    typography: {
        fontFamily:[
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    }
})

export default function App() {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getItemAsyncStorage(storageKeys.authToken).then(token => {
            getItemAsyncStorage(storageKeys.user).then(user => {
                if (token) {
                    const userData = user ? JSON.parse(user) : initialUserState
                    state.set(storageKeys.authToken, token)
                    state.set(storageKeys.user,
                        user ? Object.assign(initialUserState, userData, { isLoggedIn: true }) : userData)
                }
            }).finally(() => setIsLoading(false))
        })
    }, [])

    if (isLoading) {
        return null
    }

    return (
        <HttpsRedirect>
            <React.StrictMode>
                <CookiesProvider>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={theme}>
                            <Router>
                                <Switch>
                                    <Route exact path="/archive/:slug">
                                        <Article />
                                    </Route>
                                    <Route path="/archive">
                                        <Articles />
                                    </Route>
                                    <Route path="/blog">
                                        <Blog />
                                    </Route>
                                    <Route path="/videos">
                                        <Videos />
                                    </Route>
                                    <Route path="/about">
                                        <About />
                                    </Route>
                                    <Route path="/debate">
                                        <Debate />
                                    </Route>
                                    <Route path="/contact">
                                        <Contact />
                                    </Route>
                                    <Route path="/privacy-policy">
                                        <Privacy />
                                    </Route>
                                    <Route path="/polls/:id">
                                        <Poll />
                                    </Route>
                                    <Route path="/settings">
                                        <PrivateRoute><Settings /></PrivateRoute>
                                    </Route>
                                    <Route path="/polls">
                                        <Polls />
                                    </Route>
                                    <Route path="/request-account-deletion">
                                        <RequestAccountDeletion />
                                    </Route>
                                    <Route path="/">
                                        <Home />
                                    </Route>
                                </Switch>
                            </Router>
                        </ThemeProvider>
                    </QueryClientProvider>
                </CookiesProvider>
            </React.StrictMode>
        </HttpsRedirect>
    )
}
