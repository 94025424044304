import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { FormControl, InputLabel, Input, FormHelperText,
    CircularProgress } from '@material-ui/core'
import { useWindowDimensions } from '../../services/utils'
import { subscribe } from '../../services/client'
import { isEmailValid } from '../../services/helper'
import { CookieSetOptions } from 'universal-cookie'
import './Subscribe.css'

interface Props {
    open: boolean
    setOpen: any
    subscribeState: number
    setSubscribeState: React.Dispatch<React.SetStateAction<number>>
    cookies: { 'ocov:blogSubscribed'?: any }
    setCookie: (name: 'ocov:blogSubscribed', value: any, options?: CookieSetOptions | undefined) => void
}

export default function Subscribe(props: Props) {
    const { width } = useWindowDimensions()
    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [emailValidation, setEmailValidation] = useState({
        isValid: false,
        errorText: ''
    })
    const [errorText, setErrorText] = useState<string>('')

    const handleClose = () => {
        props.setSubscribeState(0)
        props.setOpen(false)
    }

    const emailOnBlur = () => setEmailValidation({
        isValid: isEmailValid(email),
        errorText: 'Email is not valid'
    })

    const handleSubmit = () => {
        setLoading(true)
        subscribe(email)
            .then(() => {
                setErrorText('')
                setLoading(false)
                props.setSubscribeState(2)
                props.setCookie('ocov:blogSubscribed', true, { path: '/' })
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const enabledSubscribeButton = isEmailValid(email)

    return (
        <div>
            <Dialog
                sx={{
                    '.MuiPaper-root': {
                        margin: '0 10px',
                        width: width > 768 ? 380 : 300
                    }
                }}
                open={props.open}
                onClose={handleClose}
            >
                <div className='subscripe-wrapper'>
                    {props.subscribeState !== 2 && (
                        <>
                            <p className='subscripe-title'>Subscribe</p>
                            <FormControl className='auth-form-input'>
                                <InputLabel htmlFor='email'>Email</InputLabel>
                                <Input
                                    id='email'
                                    onBlur={emailOnBlur}
                                    defaultValue={email}
                                    aria-describedby='my-helper-text'
                                    onChange={e => setEmail(e.target.value)}
                                    placeholder='Enter email'
                                />
                                {!emailValidation.isValid && (
                                    <FormHelperText className='error-text'>
                                        {emailValidation.errorText}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {errorText && <p className='subscribe-error-text'>{errorText}</p>}
                            <Button
                                type='submit'
                                variant='contained'
                                color='error'
                                className='auth-form-button'
                                disabled={!enabledSubscribeButton || loading}
                                onClick={handleSubmit}
                            >
                                {loading ? <CircularProgress size={24} /> : 'SUBMIT'}
                            </Button>
                        </>
                    )}
                    {props.subscribeState === 2 &&
                        <>
                            <div className='subscripe-title'>YOU ARE SUBSCRIBED!</div>
                            <Button
                                type='submit'
                                variant='contained'
                                color='error'
                                className='auth-form-button'
                                onClick={handleClose}
                            >
                                OK
                            </Button>
                        </>
                    }
                </div>
            </Dialog>
        </div>
    )
}