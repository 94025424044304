import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import ForgotPassword from '../../views/ForgotPassword/ForgotPassword'
import ResetPassword from '../../views/ResetPassword/ResetPassword'

import SignIn from '../../views/SignIn/SignIn'
import CreateAccount from '../../views/CreateAccount/CreateAccount'
import { useWindowDimensions } from '../../services/utils'

interface Props {
  open: boolean
  setOpen: any
  mode?: string
  handleClose?: any
}

export default function Login(props: Props) {
  const [form, setForm] = useState<string>(props.mode || 'login')
  const { width } = useWindowDimensions()

  const handleClose = () => {
    props.setOpen(false)
    if (typeof props.handleClose === 'function') props.handleClose()
  }

  return (
    <div>
      <Dialog
        sx={{
          '.MuiPaper-root': {
            margin: '0 10px',
            width: width > 768 ? 380 : 300
          }
        }}
        open={props.open}
      >
        {form !== 'reset-password' && <a className="close" onClick={handleClose}>x</a>}
        {form === 'login' && <SignIn setForm={setForm} handleClose={handleClose} />}
        {form === 'create-account' && <CreateAccount setForm={setForm} handleClose={handleClose} />}
        {form === 'forgot-password' && <ForgotPassword setForm={setForm} handleClose={handleClose} />}
        {form === 'reset-password' && <ResetPassword setForm={setForm} handleClose={handleClose} />}

      </Dialog>
    </div>
  )
}